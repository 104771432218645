import { useLoadScript } from "@react-google-maps/api";
import { AutoCompleteAddress } from "./autoCompleteAddress";

const libraries = ['places'];

export default function PlacesApiLoader(props) {
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
  
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: API_KEY,
      libraries,
    });
    return !isLoaded || loadError ? null : <AutoCompleteAddress {...props} />;
  }
  