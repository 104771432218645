import React from "react";
import moment from "moment";
import jQuery from "jquery";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

window.jQuery = window.$ = jQuery;
window.moment = moment;

class CustomDateRangePicker extends React.PureComponent {
	render() {
		const { startDate, endDate, onApply, value, dateRef, id, type, allowMaxDate = false, key, onCallback = () => {} } = this.props;

		return (
			<React.Fragment>
				<DateRangePicker
					startDate={startDate}
					endDate={endDate}
					initialSettings={type === "schedule" ? {
						autoUpdateInput: false,
						maxDate: new Date()
					} : allowMaxDate ? {
						maxSpan: { days: 6 },
						maxDate: new Date()
					} : {
						maxSpan: { days: 6 }
					}}
					onApply={onApply}
					// onCallback={(e) => console.log(e, "callback")}
					// onEvent={(e) => console.log(e, "e")}
					ref={dateRef}
					id={id}
					key={key}
					onCallback={(startDate, endDate) => {
						onCallback && onCallback(startDate, endDate);
					}}
				>
					<input
						type="text"
						className="form-control daterange-form-input roboto-regular font-size-12"
						value={value}
						readOnly
						placeholder="Choose start and end date"
					/>
				</DateRangePicker>
			</React.Fragment>
		);
	}
}
export default CustomDateRangePicker;
