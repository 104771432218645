import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAutoRefresh } from './useAutoRefresh'; // Your custom hook

// Create a context
const AutoRefreshContext = createContext();

export const AutoRefreshProvider = ({ children, callback, tabValue, loading }) => {
  const {
    timer,
    manualRefresh,
    lastRefresh,
    isAutoRefreshOn,
    setIsAutoRefreshOn,
    resetAutoRefreshTimer
  } = useAutoRefresh(callback, loading);

  useEffect(() => {
    resetAutoRefreshTimer();
  }, [tabValue]);

  return (
    <AutoRefreshContext.Provider value={{
      timer,
      manualRefresh,
      lastRefresh,
      isAutoRefreshOn,
      setIsAutoRefreshOn,
      resetAutoRefreshTimer
    }}>
      {children}
    </AutoRefreshContext.Provider>
  );
};

// Custom hook to use AutoRefresh context
export const useAutoRefreshContext = () => {
  const context = useContext(AutoRefreshContext);
  if (!context) {
    throw new Error("useAutoRefreshContext must be used within an AutoRefreshProvider");
  }
  return context;
};
