import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { mCheck, mFlag, mLoader, setIsUm, isUm } from "../../mSlice";
import Maintenance from "../layouts/Maintenance";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

export default function IndexRoute(props) {
	const { hasUserData, isMobile } = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const m = useSelector(mFlag);
	const mLoading = useSelector(mLoader);
	const [currentPath, setCurrentPath] = useState(window.location.pathname);
	const um = useSelector(isUm);

	useEffect(() => {
		history.listen((location, action) => {
			setCurrentPath(location.pathname);
		});
	}, [window.location.pathname])

	useEffect(() => {
		dispatch(mCheck());
	}, [currentPath]);

	useEffect(() => {
		if (m) {
			if (m.status === "SUCCESS") {
				const { under_maintenance } = m.content || {};
				const mc = localStorage.getItem("mc");
				if (mc == "true") {
					dispatch(setIsUm(false));
				} else {
					dispatch(setIsUm(under_maintenance));
				}
			} else if (m.status === "ERROR"){
				dispatch(setIsUm(false));
			}
		}
	}, [m])

	return (
		<React.Fragment>
			<div
				className={"ad-loading-wrapper " + (mLoading  ? "d-block" : "d-none")}
			>
				<span
					className={"loader ad-loader " + (mLoading  ? "d-block" : "d-none")}
				>
					<span className="visually-hidden">loader icon</span>
				</span>
			</div>
			{um !== "wait" && (
				um ?
					<Maintenance />
					: hasUserData ? <PrivateRoute isMobile={isMobile} /> : <PublicRoute isMobile={isMobile} />
			)}
		</React.Fragment>
	);
}
