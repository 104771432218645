import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const updateAlloMapDrawSite = createAsyncThunk(
  "hospitals/updateAlloMap",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.updateDrawSite(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const updateAlloMapSlice = createSlice({
  name: "updateAlloMap",
  initialState,
  reducers: {
    clearUpdateAlloMapResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAlloMapDrawSite.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAlloMapDrawSite.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(updateAlloMapDrawSite.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const updateAlloMapResponse = (state) => state.updateAlloMap.value;
export const updateAlloMapLoading = (state) => state.updateAlloMap.loading;
export const {clearUpdateAlloMapResponse} = updateAlloMapSlice.actions;

export default updateAlloMapSlice.reducer;
