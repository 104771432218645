import InnerNavigation from "./InnerNavigation";
import data, { refreshToken } from "../../app/services/aha-api";
import {
  HOMEHEALTH,
  ORDER_TYPE_HOME_HEALTH,
  ORDER_TYPE_ROUTINE_REDRAW,
  ORDER_TYPE_STAT,
  ORDER_TYPE_TIMED_DRAW,
  ROUTINEREDRAW,
  STATORDER,
  TIMEDDRAW,
  TYPE_HOMEHEALTH,
  TYPE_ROUTINE,
  TYPE_STAT,
  TYPE_TIMEDDRAW,
} from "../../app/utils/Constants";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isOpenReportVal, setIsOpenReport } from "../../appSlice";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  getFilesByDate,
  reportsByDate,
  reportsByDateLoader,
} from "./AdminLayoutSlice";
import moment from "moment";
import AWS from "aws-sdk";
import { exportFileDataResponse, getExportFileData } from "../../features/StatOrder/exportFileDataSlice";
import toast from "react-hot-toast";
import { deleteExportFile } from "../../features/StatOrder/deleteExportFileSlice";
import { exportDataResponse, setIsExportDisabledHomeHealth, setIsExportDisabledRoutine, setIsExportDisabledStat, setIsExportDisabledTimed } from "../../features/StatOrder/exportSlice";

export default function AdminLayout(props) {
  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const history = useHistory();
  const dispatch = useDispatch();
  const isOpenReport = useSelector(isOpenReportVal);
  const filesByDate = useSelector(reportsByDate);
  const loader = useSelector(reportsByDateLoader);
  const hideFilter = useSelector((state) => state.statGridData.hideFilter);


  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [reportDate, setReportDate] = useState("");
  const [files, setFiles] = useState({});
  const orderExportFileData = useSelector(exportFileDataResponse);
  const orderExportData = useSelector(exportDataResponse);

  let intervalId1 = useRef(null);
  let intervalId2 = useRef(null);
  let intervalId3 = useRef(null);
  let intervalId4 = useRef(null);

  useEffect(() => {
    const statExport = localStorage.getItem(`export-${ORDER_TYPE_STAT}`);
    const routineExport = localStorage.getItem(`export-${ORDER_TYPE_ROUTINE_REDRAW}`);
    const timedExport = localStorage.getItem(`export-${ORDER_TYPE_TIMED_DRAW}`);
    const hhExport = localStorage.getItem(`export-${ORDER_TYPE_HOME_HEALTH}`);

    if (statExport === 'true') {
      intervalId1.current = setInterval(() => {
        dispatch(getExportFileData({ entity: "Order", entity_type: ORDER_TYPE_STAT }));
      }, 15000);

      return () => {
        if (intervalId1.current) {
          clearInterval(intervalId1.current);
        }
      }
    }

    if (routineExport === 'true') {
      intervalId2.current = setInterval(() => {
        dispatch(getExportFileData({ entity: "Order", entity_type: ORDER_TYPE_ROUTINE_REDRAW }));
      }, 15000);

      return () => {
        if (intervalId2.current) {
          clearInterval(intervalId2.current);
        }
      }
    }

    if (timedExport === 'true') {
      intervalId3.current = setInterval(() => {
        dispatch(getExportFileData({ entity: "Order", entity_type: ORDER_TYPE_TIMED_DRAW }));
      }, 15000);

      return () => {
        if (intervalId3.current) {
          clearInterval(intervalId3.current);
        }
      }
    }

    if (hhExport === 'true') {
      intervalId4.current = setInterval(() => {
        dispatch(getExportFileData({ entity: "Order", entity_type: ORDER_TYPE_HOME_HEALTH }));
      }, 15000);

      return () => {
        if (intervalId4.current) {
          clearInterval(intervalId4.current);
        }
      }
    }
  }, [currentPath, orderExportData]);

  useEffect(() => {
    if (orderExportFileData && orderExportFileData.status === "SUCCESS") {
      if (orderExportFileData.content) {
        if (orderExportFileData.content.entity_type === ORDER_TYPE_STAT) {
          clearInterval(intervalId1.current);
        } else if (orderExportFileData.content.entity_type === ORDER_TYPE_ROUTINE_REDRAW) {
          clearInterval(intervalId2.current);
        } else if (orderExportFileData.content.entity_type === ORDER_TYPE_TIMED_DRAW) {
          clearInterval(intervalId3.current);
        } else if (orderExportFileData.content.entity_type === ORDER_TYPE_HOME_HEALTH) {
          clearInterval(intervalId4.current);
        }
        getPresignedUrl(orderExportFileData.content, orderExportFileData.content.entity_type);
      }
    }
  }, [orderExportFileData]);

  useEffect(() => {
    history.listen((location, action) => {
      setCurrentPath(location.pathname);
    });
  }, [window.location.pathname]);

  useEffect(() => {
    if (filesByDate) {
      if (filesByDate.status === "SUCCESS") {
        const { content = {} } = filesByDate;
        setFiles(content);
      }
    }
  }, [filesByDate]);

  const handleClose = () => {
    dispatch(setIsOpenReport(false));
    setReportDate("");
    setFiles({});
  };

  const handleChangeDate = (e) => {
    dispatch(getFilesByDate({ date: moment(e).format("YYYY-MM-DD") }));
    setReportDate(e);
  };

  const updateExcelExportButtonStatus = (type, value) => {
    if (type === ORDER_TYPE_STAT) {
      dispatch(setIsExportDisabledStat(value));
    } else if (type === ORDER_TYPE_ROUTINE_REDRAW) {
      dispatch(setIsExportDisabledRoutine(value));
    } else if (type === ORDER_TYPE_TIMED_DRAW) {
      dispatch(setIsExportDisabledTimed(value));
    } else if (type === ORDER_TYPE_HOME_HEALTH) {
      dispatch(setIsExportDisabledHomeHealth(value));
    }
  }

  const getPresignedUrl = (file, type) => {
    let userData = window.localStorage.getItem("userData") || "";
    userData = userData ? JSON.parse(userData) : {};
    const { access_key_id, secret_key, session_token } = userData;

    const ACCESS_KEY_ID = access_key_id;
    const SECRET_ACCESS_KEY = secret_key;
    const SESSION_TOKEN = session_token;
    downloadFile(file, {
      accessKeyId: ACCESS_KEY_ID,
      secretAccessKey: SECRET_ACCESS_KEY,
      sessionToken: SESSION_TOKEN,
    }, type);
  };

  const downloadFile = async (file, creds, type) => {
    const myBucket = file.bucket_name;
    const myKey = file.file_url;

    const signedUrlExpireSeconds = 60 * 60 * 24;
    let s3 = new AWS.S3({
      accessKeyId: creds.accessKeyId,
      signatureVersion: "v4",
      region: "us-east-1",
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    });

    try {
      if (s3) {
        let sessionTokenTime =
          (window.localStorage.getItem("userData") &&
            JSON.parse(window.localStorage.getItem("userData"))
              .session_token_expiration) ||
          "";

        if (
          sessionTokenTime &&
          Date.parse(new Date(sessionTokenTime)) <= Date.parse(new Date())
        ) {
          const creds = await refreshToken();
          await downloadFile(file, creds, type);
        } else {
          console.log(s3, "iii2");
          const url = s3.getSignedUrl("getObject", {
            Bucket: myBucket,
            Key: myKey,
            Expires: signedUrlExpireSeconds,
          });

          if (type) {
            var el = document.createElement("a");
            el.setAttribute("href", url);
            el.setAttribute("download", url);
            document.body.appendChild(el);
            el.click();
            el.remove();
            localStorage.removeItem(`export-${type}`);
            updateExcelExportButtonStatus(type, false);
            toast((t) =>
              <div className="file-toaster">
                <div className="toaster-close">
                  <span
                    className="close-icon"
                    data-testid="toast-header"
                    onClick={() => {
                      toast.dismiss(t.id);
                      dispatch(deleteExportFile(`entity=Order&entity_type=${type}`));
                    }}
                    aria-label="Close"
                  >
                    <span className="visually-hidden">Close Icon</span>
                  </span>
                </div>
                <div>
                  <div className="toast-info-details d-flex align-items-center">
                    <span className={`checkmark-with-circle pr-10`}> <span className="visually-hidden">
                      Checkmark icon with circle
                    </span></span>
                    <p className="font-size-12 custom-toast-msg-text">Your file is now downloading.</p>
                  </div>
                </div></div>
              , {
                position: "bottom-right",
                duration: Infinity,
                className: "export-file-toaster-wrapper"
              })
          } else {
            window.open(url);
          }
        }
      }
    } catch (err) {
      console.log(err, err.message, s3, "1112");
      const creds = await refreshToken();
      console.log(creds, "credscreds2");
      await downloadFile(file, creds, type);
    }
  };

  return (
    <>
      <div className="ad-main-container">
        <div className="ad-left-wrapper">
          <InnerNavigation />
        </div>
        <div className="ad-right-wrapper">
          {data.badge === "stage" && <div className="test-badge"></div>}
          {data.badge === "preprod" && (
            <div className="test-badge test-badge-preprod"></div>
          )}
          {/* <div className="test-badge test-badge-preprod"></div> */}
          <div
            className={
              currentPath === STATORDER ||
                currentPath === ROUTINEREDRAW ||
                currentPath === TIMEDDRAW ||
                currentPath === HOMEHEALTH
                ? `ad-right-stat-order-content-wrapper ${hideFilter ? 'so-toggle-active' : ''}`
                : "ad-right-content-wrapper"
            }
          >
            {props.children}
          </div>
        </div>
        <Modal
          show={isOpenReport}
          onHide={handleClose}
          className="ad-adduser-modal sc-copycal-modal report-modal create-order-modal"
          backdrop="static"
          enforceFocus={false}
          data-testid="report-modal-unique"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="reports-icon-wrapper">
              <span className="icon-monitoring report-icon">
                <span className="visually-hidden">Reports Icon</span>
              </span>
            </div>
            <div className={"aha-modalform-group datetime-picker-wrapper "}>
              <label className="caption" htmlFor="reports-date">
                Select Date
              </label>
              <DatePicker
                selected={reportDate}
                dateFormat="MM/dd/yyyy"
                placeholderText="--"
                name="stat-patient-dob"
                id="reports-date"
                onChange={(e) => handleChangeDate(e)}
                maxDate={yesterdayDate}
                calendarClassName="st-react-datepicker-wrapper"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                autoComplete="off"
              // todayButton="Today"
              />
              <span className="date-icon">
                <span className="visually-hidden">date-icon</span>
              </span>
            </div>
            <div
              className={
                "loader ad-loader-report mt-3 " +
                (loader ? "d-block" : "d-none")
              }
            ></div>
            {Object.keys(files).length > 0 && (
              <div className="reports-list-tablewrapper">
                <table className="table m-0">
                  <tbody>
                    {Object.keys(files).map((fileDate) => {
                      const subFiles = files[fileDate] || {};
                      return subFiles.map((file, idx) => {
                        const { file_name, file_url = "" } = file;
                        return (
                          <tr key={idx}>
                            <td>{file_name}</td>
                            <td
                              className="roboto-bold"
                              onClick={() => getPresignedUrl(file)}
                            >
                              <a href="#">Download</a>
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {reportDate && Object.keys(files).length === 0 && !loader && (
              <p className="font-size-12 mt-2">No reports available</p>
            )}
            <div onClick={handleClose}>
              <a href="#" className="font-size-10 backto-dashboard">
                Return to Dashboard
              </a>{" "}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
