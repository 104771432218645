import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  profileList,
} from "../LandingPage/Profile/profileSlice";
import Avatar from "react-avatar";
import moment from "moment";
import {
  homeHealthScheduleList,
  homeHealthScheduleListList,
  homeHealthScheduleListLoading,
  updateScheduleListValue,
} from "./Operations/scheduleListSlice";
import { filterList, filterListLoading, getFilter } from "../LandingPage/AddUser/filterSlice";
import {
  getManagerHH,
  getManagerList,
  managerListLoading,
  updateReportingDataVal,
} from "./Operations/reportingSlice";
import _ from "lodash";
import ToastMessageNew from "../../components/layouts/ToastMessageNew";
import {
  homeHealthScheduleFilterState,
  setFilter,
  setHomeHealthFilterObject,
  setHomeHealthScheduleDate,
} from "./Operations/filterSlice";
import {
  careAtHomeScheduleClass,
} from "../../app/utils/Constants";
import Calendar from "./calendar";
import Filter from "./filter";
import { CommonHeaderRightSection } from "../../components/commons/CommonHeaderRightSection";
import { getPhlebHH, getPhlebList, phlebListLoading, updatePhlebDataVal } from "./Operations/phlebSlice";

export const HomeHealthCalendar = () => {
  let interval;
  const [paused, setPaused] = React.useState(false);
  const username = localStorage.getItem("username");

  const dispatch = useDispatch();
  const profile = useSelector(profileList);
  const schedulerList = useSelector(homeHealthScheduleListList);
  const reportingData = useSelector(getManagerList);
  const phlebData = useSelector(getPhlebList);

  const homeHealthScheduleListLoader = useSelector(homeHealthScheduleListLoading);

  const [scheduleBoardData, setBoardData] = React.useState([]);

  const filter = useSelector(filterList);
  const stateLoader = useSelector(filterListLoading);
  let stateOptions = filter ? filter ? filter.content ? filter.content.states : [] : [] : [];
  stateOptions = stateOptions ? stateOptions.map(opt => { return { ...opt, label: opt.name, value: opt.id } }) : [];
  console.log(stateOptions, 'stateOptions');
  const [name, setName] = React.useState("");
  const [color, setColor] = React.useState("");

  const reduxState = useSelector(homeHealthScheduleFilterState);
  const managerListLoader = useSelector(managerListLoading);
  const phlebListLoader = useSelector(phlebListLoading);
  const [componentFilter, setComponentFilter] = React.useState({});
  const [toast, setToast] = React.useState(true);
  const [toastMessage, setToastMessage] = React.useState("");

  const [reportingOptions, setReportingOptions] = React.useState([]);
  const [phlebOptions, setPhlebOptions] = React.useState([]);

  const [searchText, setSearchText] = React.useState("");
  const [isDataChanged, setIsDataChanged] = React.useState(true);

  const [isFirstLoad, setIsFirstLoad] = React.useState(true);


  const { filters = {}, date = new Date() } = componentFilter || {};
  const {
    states = [],
    managers = [],
    phlebs = [],
  } = filters;
  console.log(filters, 'filtersfiltersfilters');
  document.documentElement.classList.add(careAtHomeScheduleClass);

  const roles =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles.length ===
      1 &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles) ||
    [];

  const isPhleb = roles && roles.includes("Phlebotomist");

  React.useEffect(() => {
    dispatch(getProfile());
    dispatch(getFilter());
    const { filters = {}, selectedDate = new Date() } =
      window.localStorage.getItem(`${username}.homeHealthScheduleFilter`)
        ? JSON.parse(window.localStorage.getItem(`${username}.homeHealthScheduleFilter`))
        : {};
    const {
      states = [],
      managers: sManager = [],
      phlebs = [],
    } = filters;
    dispatch(
      setHomeHealthFilterObject({ property: "filters", value: filters })
    );
    if (!_.isEmpty(sManager) &&
      !_.isEmpty(phlebs)) {
      let managers = sManager
        ? Array.isArray(sManager)
          ? sManager.map((val) => parseInt(val.value))
          : localStorage.setItem(
            `${username}.homeHealthScheduleFilter`,
            JSON.stringify({
              filters: { ...filters, managers: [] },
              selectedDate: date,
            })
          )
        : [];
      let tempPhlebs = phlebs
        ? Array.isArray(phlebs)
          ? phlebs.map((val) => parseInt(val.value))
          : localStorage.setItem(
            `${username}.homeHealthScheduleFilter`,
            JSON.stringify({
              filters: { ...filters, phlebs: [] },
              selectedDate: date,
            })
          )
        : [];
      let tempStates = states
        ? Array.isArray(states)
          ? states.map((val) => val.value)
          : []
        : [];
      const payloadDate = moment(new Date(selectedDate)).format(
        "YYYY-MM-DD"
      );
      const data = {
        scheduled_date: payloadDate,
        states: tempStates,
        reporting_manager_id: managers,
        user_ids: tempPhlebs.length ? tempPhlebs : [],
      };
      dispatch(homeHealthScheduleList(data));
    }
    dispatch(getManagerHH({}));

    if (!_.isEmpty(sManager)) {
      const managerPayload = {
        reporting_manager_id: sManager
          ? Array.isArray(sManager)
            ? sManager.map((val) => parseInt(val.value))
            : []
          : [],
      };
      dispatch(getPhlebHH(managerPayload));
    }
    if (!_.isEmpty(selectedDate)) {
      dispatch(setHomeHealthScheduleDate(new Date(selectedDate)));
    }
  }, []);

  React.useEffect(() => {
    if (reportingData) {
      if (reportingData.status === "SUCCESS") {
        const { content = {} } = reportingData;
        let { users = [] } = content || {};

        users = users
          .map((val) => {
            return { value: val.user_id, label: val.full_name };
          })
          .sort((a, b) => a.label.localeCompare(b.label));

        const { filters = {} } =
          window.localStorage.getItem(`${username}.homeHealthScheduleFilter`)
            ? JSON.parse(window.localStorage.getItem(`${username}.homeHealthScheduleFilter`))
            : {};
        const { managers = [] } = filters;

        if (managers && managers.length) {
          let temp = managers;
          managers.forEach((mg) => {
            if (users.some((user) => user.value === mg.value)) {
            } else {
              temp = temp.filter((t) => t.value !== mg.value);
            }
          });
          dispatch(
            setFilter({
              parent: "filters",
              property: "managers",
              value: temp,
            })
          );
          localStorage.setItem(
            `${username}.homeHealthScheduleFilter`,
            JSON.stringify({
              filters: { ...filters, managers: temp },
              selectedDate: date,
            })
          );
          // const managerIds = users.map((val) => parseInt(val.value)) || [];
          // handleGetManager(managerIds);
        }
        setReportingOptions(users);
      } else if (reportingData.status === "ERROR" && reportingData.message) {
        setToast(false);
        setToastMessage(reportingData.message);
      }
    }
  }, [reportingData]);

  React.useEffect(() => {
    if (phlebData) {
      if (phlebData.status === "SUCCESS") {
        const { content = {} } = phlebData;
        let { users = [] } = content || {};

        const { filters = {} } =
          window.localStorage.getItem(`${username}.homeHealthScheduleFilter`)
            ? JSON.parse(window.localStorage.getItem(`${username}.homeHealthScheduleFilter`))
            : {};
        const { phlebs = [] } = filters;

        users = users
          .map((val) => {
            return { value: val.user_id, label: val.full_name };
          })
          .sort((a, b) => a.label.localeCompare(b.label));

        let finalUsers = [...users];
        // if (!isFirstLoad) {
        // } else {
          const isSelectAllSelected = phlebs?.length > 0 && phlebs.length === (phlebOptions ? phlebOptions.length : 0);
          if (isSelectAllSelected) {
          } else {
            if (phlebs && phlebs.length) {
              finalUsers = phlebs;
              phlebs.forEach((mg) => {
                if (users.some((user) => user.value === mg.value)) {
                } else {
                  finalUsers = finalUsers.filter((t) => t.value !== mg.value);
                }
              });
            }
          }
        //   setIsFirstLoad(false);
        // }

        dispatch(
          setFilter({
            parent: "filters",
            property: "phlebs",
            value: finalUsers,
          })
        );
        localStorage.setItem(
          `${username}.homeHealthScheduleFilter`,
          JSON.stringify({
            filters: { ...filters, phlebs: finalUsers },
            selectedDate: date,
          })
        );
        setPhlebOptions(users);
      } else if (phlebData.status === "ERROR" && phlebData.message) {
        setToast(false);
        setToastMessage(phlebData.message);
      }
    }
  }, [phlebData]);

  React.useEffect(() => {
    if (!paused) {
      interval = setInterval(() => {
        handleAuto();
      }, 15000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    managers,
    phlebs,
    states,
    date,
    paused,
  ]);

  React.useEffect(() => {
    if (profile && profile.status === "SUCCESS") {
      if (profile && profile.content) {
        localStorage.setItem(
          "userDetail",
          JSON.stringify(profile && profile.content)
        );
        setName(profile && profile.content && profile.content.name);
        setColor(profile && profile.content && profile.content.icon_color_code);
      }
    }
  }, [profile]);

  React.useEffect(() => {
    if (schedulerList && schedulerList.status) {
      if (schedulerList.status === "SUCCESS") {
        let { content = [] } = schedulerList;
        setBoardData(content || []);
      } else if (schedulerList.status === "ERROR" && schedulerList.message) {
        setToast(false);
        setToastMessage(schedulerList.message);
      }
    } else {
      setBoardData([]);
    }
  }, [schedulerList]);

  React.useEffect(() => {
    setComponentFilter(reduxState);
  }, [reduxState]);

  React.useEffect(
    () => () => {
      //clear redux state
      dispatch(updateScheduleListValue({}));
      dispatch(updatePhlebDataVal({}));
      dispatch(updateReportingDataVal({}));
      document.documentElement.classList.remove(careAtHomeScheduleClass);
    },
    []
  );


  const handleEventForManager = (states = [], managers = [], newDate, allPhlebs = []) => {
    const payloadDate = moment(newDate).format("YYYY-MM-DD");
    const newManagers = managers.map((val) => parseInt(val.value)) || [];
    const newStates = states.map((val) => val.value) || [];
    const data = {
      scheduled_date: payloadDate,
      reporting_manager_id: newManagers,
      states: newStates,
      user_ids: allPhlebs,
    };
    dispatch(homeHealthScheduleList(data));
  };

  const logout = () => {
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem("userDetail");
    // window.localStorage.removeItem("homeHealthScheduleFilter");
    window.location.href = "/";
  };

  const handleAuto = () => {
    if (!_.isEmpty(managers) && !_.isEmpty(phlebs)) { 
      const payloadDate = moment(date).format("YYYY-MM-DD");
      const newManagers = managers.map((val) => parseInt(val.value)) || [];
      const newStates = states.map((val) => val.value) || [];
      const data = {
        scheduled_date: payloadDate,
        reporting_manager_id: newManagers,
        states: newStates,
        user_ids: phlebs
          ? phlebs.length
            ? phlebs.map((val) => parseInt(val.value))
            : []
          : []
      };
      dispatch(homeHealthScheduleList(data));
    }
  };

  const handleGetManager = (managers = []) => {
    const data = {};
    if (managers && managers.length) {
      data.reporting_manager_id = managers;
      dispatch(getPhlebHH(data));
    } else {
      dispatch(getManagerHH(data));
    }
  };

  const handleManager = (e) => {
    let newFilterData = {...filters, managers: e};
    dispatch(
      setFilter({ parent: "filters", property: "managers", value: e })
    );
    if (!e || !e.length) {
      newFilterData = {...newFilterData, phlebs: []};
      dispatch(
        setFilter({ parent: "filters", property: "phlebs", value: [] })
      );
      setBoardData([]);
    }
    localStorage.setItem(
      `${username}.homeHealthScheduleFilter`,
      JSON.stringify({
        filters: newFilterData,
        selectedDate: date,
      })
    );
  };

  const handlePhleb = (e) => {
    dispatch(
      setFilter({ parent: "filters", property: "phlebs", value: e })
    );
    localStorage.setItem(
      `${username}.homeHealthScheduleFilter`,
      JSON.stringify({
        filters: { ...filters, phlebs: e },
        selectedDate: date,
      })
    );
    if (!e || !e.length) {
      date &&
        managers &&
        managers.length > 0 &&
        handleEventForManager(states, managers, date);
    }
  };
  const handleStates = (e) => {
    dispatch(
      setFilter({ parent: "filters", property: "states", value: e })
    );
    localStorage.setItem(
      `${username}.homeHealthScheduleFilter`,
      JSON.stringify({
        filters: { ...filters, states: e },
        selectedDate: date,
      })
    );
  };
  const handleStatesOnBlur = () => {
    let allPhlebs = [];
    allPhlebs = phlebs ? phlebs.map((ph) => ph.value) : [];
    handleEventForManager(states, managers, date, allPhlebs);
  };
  const handleManagerOnBlur = () => {
    if (date && managers && managers.length > 0) {
      let allPhlebs = [];
      if (phlebs && phlebs.length > 0) {
        allPhlebs = phlebs.map((ph) => ph.value);
      }
      handleEventForManager(states, managers, date, allPhlebs);
    }

    const newManagers = managers.map((val) => parseInt(val.value)) || [];
    handleGetManager(newManagers);
  };
  const handlePhlebOnBlur = () => {
    if (date && managers && managers.length > 0 && phlebs && phlebs.length > 0) {
      let allPhlebs = [];
      allPhlebs = phlebs.map((ph) => ph.value);
      handleEventForManager(states, managers, date, allPhlebs);
    }
  };
  const handleChangeDate = (val) => {
    dispatch(updateScheduleListValue({}));
    localStorage.setItem(
      `${username}.homeHealthScheduleFilter`,
      JSON.stringify({
        selectedDate: val,
        filters,
      })
    );
    setIsDataChanged(true);
    dispatch(setHomeHealthScheduleDate(val));
    if (!_.isEmpty(managers) && !_.isEmpty(phlebs)) {
      let allPhlebs = [];
      allPhlebs = phlebs ? phlebs.map((ph) => ph.value) : [];
      handleEventForManager(states, managers, val, allPhlebs);
    }
  }
  const loading = homeHealthScheduleListLoader;
  return (
    <React.Fragment>
      <div className="ad-headertop-wrapper scheduler-header">
        <CommonHeaderRightSection
          logout={logout}
          color={color}
          name={name}
          title={"Care at Home Schedule"}
          className="d-flex"
          callbackToRefreshThePage={() => handleAuto()}
        />
      </div>
      {!isPhleb ? <div className="scheduler-tabs-wrapper">
        <div
          className={
            "ad-loading-wrapper " +
            (loading ? "d-block loader-wrapper" : "d-none")
          }
        >
          <span
            className={"loader ad-loader " + (loading ? "d-block" : "d-none")}
          >
            <span className="visually-hidden">loader icon</span>
          </span>
        </div>
        <Filter
          date={date}
          handleManager={handleManager}
          manager={managers}
          reportingOptions={reportingOptions}
          handleManagerOnBlur={handleManagerOnBlur}
          handlePhleb={handlePhleb}
          phlebs={phlebs}
          phlebOptions={phlebOptions}
          states={states}
          stateOptions={stateOptions ? stateOptions.length ? stateOptions : [] : []}
          handlePhlebOnBlur={handlePhlebOnBlur}
          onDateChange={handleChangeDate}
          handleStatesOnBlur={handleStatesOnBlur}
          handleStates={handleStates}
          searchText={searchText}
          setSearchText={setSearchText}
          phlebListLoader={phlebListLoader}
          managerListLoader={managerListLoader}
          stateLoader={stateLoader}
        />
        {!loading && (!managers || !managers.length || !phlebs || !phlebs.length) ?
          <div className="scheduler-wrapper">
            <div
              className="toast toast-wrapper text-center show sch-toast-wrapper"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-info-details d-flex align-items-center toast-body">
                <p className="sch-info-text">
                  Please select Reporting Manager and Phlebotomist to view the Schedule
                </p>
              </div>
            </div>
          </div> :
          <Calendar date={date} calendarData={scheduleBoardData} handleChangeDate={handleChangeDate}
            searchText={searchText} loading={loading} isDataChanged={isDataChanged} setIsDataChanged={setIsDataChanged}
          />
        }
        {/* GRIDDD */}
      </div> : <p className="black-color my-3">
        You do not have access to view this page
      </p>}
      <ToastMessageNew
        type={"error"}
        message={toastMessage}
        setToast={setToast}
        toast={toast}
      />
    </React.Fragment>
  );
};
