import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "../features/accounts/Login/accountSlice";
import inviteReducer from "../features/accounts/SetPassword/inviteSlice";
import setPasswordReducer from "../features/accounts/SetPassword/setPasswordSlice";
import forgotPasswordReducer from "../features/accounts/TroubleLogin/forgotPasswordSlice";
import forgotUserReducer from "../features/accounts/TroubleLogin/forgotUserSlice";
import resetPasswordReducer from "../features/accounts/TroubleLogin/resetPasswordSlice";
import logger from "redux-logger";
import verifyCodeReducer from "../features/accounts/Login/verifyCodeSlice";
import userReducer from "../features/LandingPage/userSlice";
import filterReducer from "../features/LandingPage/AddUser/filterSlice";
import addUserReducer from "../features/LandingPage/AddUser/addUserSlice";
import resendInviteSlice from "../features/accounts/SetPassword/resendInviteSlice";
import contactSlice from "../features/accounts/TroubleLogin/contactSlice";
import updateUserReducer from "../features/LandingPage/UpdateUser/updateUserSlice";
import lostDeviceReducer from "../features/accounts/Login/lostDeviceSlice";
import confirmLostDeviceReducer from "../features/accounts/Login/confirmLostDeviceSlice";
import profileReducer from "../features/LandingPage/Profile/profileSlice";
import facilitiesReducer from "../features/LandingPage/AddUser/facilitiesSlice";
import addFacilitiesReducer from "../features/LandingPage/FacilityAddEdit/AddFacilitySlice";
import addHospitalsReducer from "../features/LandingPage/HospitalAddEdit/AddHospitalSlice";
import addLabsReducer from "../features/LandingPage/LabAddEdit/AddLabSlice";
import addTerritoriesReducer from "../features/LandingPage/TerritoryAddEdit/AddTerritorySlice";
import addZonesReducer from "../features/LandingPage/ZoneAddEdit/AddZoneSlice";
import updateFacilityReducer from "../features/LandingPage/FacilityAddEdit/UpdateFacilitySlice";
import updateHospitalReducer from "../features/LandingPage/HospitalAddEdit/UpdateHospitalSlice";
import updateLabReducer from "../features/LandingPage/LabAddEdit/UpdateLabSlice";
import updateTerritoryReducer from "../features/LandingPage/TerritoryAddEdit/UpdateTerritorySlice";
import updateZoneReducer from "../features/LandingPage/ZoneAddEdit/UpdateZoneSlice";
import labDataReducer from "../features/LandingPage/LabAddEdit/LabDataSlice";
import scheduleListSlice from "../features/Scheduler/Operations/scheduleListSlice";
import scheduleFilterSlice from "../features/Scheduler/Operations/filterSlice";
import backlogSlice from "../features/Scheduler/Operations/backlogSlice";
import backlogNonServiceSlice from "../features/Scheduler/Operations/backlogNonServiceSlice";
import deleteEventSlice from "../features/Scheduler/Operations/deleteSlice";
import getManagerSlice from "../features/Scheduler/Operations/reportingSlice";
import updateEventSlice from "../features/Scheduler/Operations/eventSlice";
import deleteServiceSlice from "../features/Scheduler/Operations/deleteServiceSlice";
import filterOptionsSlice from "../features/Tracker/filterOptionsSlice";
import copyScheduleSlice from "../features/Scheduler/Operations/copyScheduleSlice";
import tokenSlice from "../features/accounts/Login/tokenSlice";
import checkinCheckoutSlice from "../features/Scheduler/Operations/checkinCheckoutSlice";
import getLabsSlice from "../features/Scheduler/Operations/getLabsSlice";
import getHospitalsSlice from "../features/Scheduler/Operations/getHospitalsSlice";
import downloadSlice from "../features/Scheduler/Operations/downloadSlice";
import markersSlice from "../features/Tracker/markersSlice";
import subSetUserReducer from "../features/LandingPage/subSetUserSlice";
import hospitalsReducer from "../features/LandingPage/HospitalAddEdit/HospitalsSlice";
import labsReducer from "../features/LandingPage/LabAddEdit/LabsSlice";
import territoriesReducer from "../features/LandingPage/TerritoryAddEdit/TerritoriesSlice";
import zonesReducer from "../features/LandingPage/ZoneAddEdit/ZonesSlice";
import appSlice from "../appSlice";
import appSliceForLogin from "../appSliceForLogin";
import sessionTimeoutSlice from "../components/sessionTimeout/sessionTimeoutSlice";
import addStatOrdersReducer from "../features/StatOrder/addStatOrderSlice";
import updateStatOrdersReducer from "../features/StatOrder/updateStatOrderSlice";
import addRoutineRedrawsReducer from "../features/RoutineRedraw/addRoutineRedrawSlice";
import updateRoutineRedrawsReducer from "../features/RoutineRedraw/updateRoutineRedrawSlice";
import addTimedDrawsReducer from "../features/TimedDraw/addTimedDrawSlice";
import updateTimedDrawsReducer from "../features/TimedDraw/updateTimedDrawSlice";
import orderFilterListReducer from "../features/StatOrder/orderFilterListSlice";
import onCallBacklogSlice from "../features/Scheduler/Operations/onCallBacklogSlice";
import onCallScheduleListSlice from "../features/Scheduler/Operations/onCallScheduleListSlice";
import onCallDeleteServiceSlice from "../features/Scheduler/Operations/onCallDeleteServiceSlice";
import bulkCheckinCheckoutSlice from "../features/StatOrder/bulkCheckinCheckout";
import bulkCheckinCheckoutHomeHealthSlice from "../features/HomeHealth/bulkCheckinCheckoutHomeHealth";
import acknowledgePartialOrderSlice from "../features/StatOrder/acknowledgePartialOrder";
import unclaimOrderSlice from "../features/HomeHealth/unclaimOrderSlice";
import forceAcknowledgeOrderSlice from "../features/StatOrder/forceAcknowledgeOrder";
import chatPermissionSlice from "../features/Chat/operations/chatPermissionSlice";
import adminLayoutSlice from "../components/layouts/AdminLayoutSlice";
import mSlice from "../mSlice";
import surgeOrNonServiceFacilitiesSlice from "../features/Scheduler/Operations/getSurgeOrNonServiceFacilitiesSlice";
import updateReportingManagerSlice from "../features/LandingPage/assignReportingManagerSlice";
import addHomeHealthReducer from "../features/HomeHealth/addHomeHealthSlice";
import updateHomeHealthsReducer from "../features/HomeHealth/updateHomeHealthSlice";
import orderSlice from "../features/StatOrder/orderSlice";
import checkHomeHealthUserScheduleSlice from "../features/HomeHealth/homeHealthUserSchedule";
import cancelHomeHealthReducer from "../features/HomeHealth/cancelHomeHealthSlice";
import uncancelHomeHealthReducer from "../features/HomeHealth/uncancelHomeHealthSlice";
import fetchDataByPartnerOrderIdReducer from "../features/HomeHealth/fetchDataByPartnerOrderId";
import userSliceReducer from "../features/LandingPage/Profile/userSlice";
import facilitySliceReducer from "../features/LandingPage/FacilityAddEdit/facilitySlice";
import hospitalSliceReducer from "../features/LandingPage/HospitalAddEdit/hospitalSlice";
import labSliceReducer from "../features/LandingPage/LabAddEdit/labSlice";
import territorySliceReducer from "../features/LandingPage/TerritoryAddEdit/territorySlice";
import zoneSliceReducer from "../features/LandingPage/ZoneAddEdit/zoneSlice";
import deleteRoutineActionReducer from "../features/Scheduler/Operations/deleteRoutineAction";
import deleteStatActionReducer from "../features/StatOrder/deleteStatAction";
import assignFacilitiesToZoneReducer from "../features/LandingPage/assignFacilitiesToZoneSlice";
import zonesListReducer from "../features/LandingPage/zonesSlice";
import activeZonesListReducer from "../features/LandingPage/activeZonesSlice";
import activeZonesListWithDateReducer from "../features/LandingPage/activeZonesWithDateSlice";
import cancelStatOrderReducer from "../features/StatOrder/cancelStatOrderSlice";
import cloneHomeHealthReducer from "../features/HomeHealth/cloneHomeHealthSlice";
import searchGlobalReducer from "../features/GlobalSearch/globalSearchSlice";
import shiftTimingsReducer from "../features/Scheduler/Operations/shiftTimingsSlice";
import homeHealthScheduleFilterReducer from "../features/HomeHealthScheduler/Operations/filterSlice";
import homeHealthScheduleListReducer from "../features/HomeHealthScheduler/Operations/scheduleListSlice";
import getManagerReducer from "../features/HomeHealthScheduler/Operations/reportingSlice";
import getPhlebReducer from "../features/HomeHealthScheduler/Operations/phlebSlice";
import getRoutineManagerReducer from "../features/Scheduler/Operations/routineReportingSlice";
// import parentAccountNumberReducer from "../features/StatOrder/parentAccountNumberSlice";
import hospitalFilterReducer from "../features/LandingPage/HospitalAddEdit/hospitalFilterSlice";
import orderExportReducer from "../features/StatOrder/exportSlice";
import orderExportFileReducer from "../features/StatOrder/exportFileDataSlice";
import deleteExportFileReducer from "../features/StatOrder/deleteExportFileSlice";

// reducers for order pages
import { statReducer } from '../features/StatOrder/statOrderReducer';
import { routineRedrawReducer } from '../features/RoutineRedraw/routineRedrawReducer';
import { timedDrawReducer } from '../features/TimedDraw/timedDrawReducer';
import { homeHealthReducer } from '../features/HomeHealth/homeHealthReducer';
import getAlloMapReducer from "../features/LandingPage/AlloMap/getAlloMapDrawSitesSlice";
import addAlloMapReducer from "../features/LandingPage/AlloMap/addAlloMapSlice";
import updateAlloMapReducer from "../features/LandingPage/AlloMap/updateAlloMapSlice";
import fetchAlloMapReducer from "../features/LandingPage/AlloMap/fetchAlloMapSlice";


export const store = configureStore({
  reducer: {
    account: accountReducer,
    invite: inviteReducer,
    verifyCode: verifyCodeReducer,
    setPass: setPasswordReducer,
    forgotPassword: forgotPasswordReducer,
    forgotUser: forgotUserReducer,
    resetPassword: resetPasswordReducer,
    users: userReducer,
    subSetUsers: subSetUserReducer,
    filter: filterReducer,
    addUser: addUserReducer,
    resendInvite: resendInviteSlice,
    contact: contactSlice,
    updateUser: updateUserReducer,
    lostDevice: lostDeviceReducer,
    confirmLostDevice: confirmLostDeviceReducer,
    profile: profileReducer,
    facilities: facilitiesReducer,
    addFacilities: addFacilitiesReducer,
    updateFacility: updateFacilityReducer,
    scheduleList: scheduleListSlice,
    scheduleFilter: scheduleFilterSlice,
    backlog: backlogSlice,
    backlognonservice: backlogNonServiceSlice,
    deleteEvent: deleteEventSlice,
    getManager: getManagerSlice,
    updateEvent: updateEventSlice,
    deleteService: deleteServiceSlice,
    mapFilterOptions: filterOptionsSlice,
    copySchedule: copyScheduleSlice,
    token: tokenSlice,
    checkinCheckout: checkinCheckoutSlice,
    labsList: getLabsSlice,
    hospitalsList: getHospitalsSlice,
    download: downloadSlice,
    markersFeatures: markersSlice,
    hospitals: hospitalsReducer,
    updateHospital: updateHospitalReducer,
    addHospitals: addHospitalsReducer,
    labs: labsReducer,
    updateLab: updateLabReducer,
    addLabs: addLabsReducer,
    labData: labDataReducer,
    territories: territoriesReducer,
    updateTerritory: updateTerritoryReducer,
    addTerritories: addTerritoriesReducer,
    zones: zonesReducer,
    updateZone: updateZoneReducer,
    addZones: addZonesReducer,
    appData: appSlice,
    appDataForLogin: appSliceForLogin,
    order: orderSlice,
    sessionTimeout: sessionTimeoutSlice,
    addStatOrders: addStatOrdersReducer,
    updateStatOrders: updateStatOrdersReducer,
    addRoutineRedraws: addRoutineRedrawsReducer,
    updateRoutineRedraws: updateRoutineRedrawsReducer,
    addTimedDraws: addTimedDrawsReducer,
    updateTimedDraws: updateTimedDrawsReducer,
    orderFilterList: orderFilterListReducer,
    onCallBacklog: onCallBacklogSlice,
    onCallScheduleList: onCallScheduleListSlice,
    onCallDeleteService: onCallDeleteServiceSlice,
    bulkCheckinCheckoutState: bulkCheckinCheckoutSlice,
    bulkCheckinCheckoutHomeHealthState: bulkCheckinCheckoutHomeHealthSlice,
    acknowledgePartialOrderState: acknowledgePartialOrderSlice,
    unclaimOrderState: unclaimOrderSlice,
    forceAcknowledgeOrderState: forceAcknowledgeOrderSlice,
    systemChatPermission: chatPermissionSlice,
    reports: adminLayoutSlice,
    md: mSlice,
    surgeOrNonServiceFacilities: surgeOrNonServiceFacilitiesSlice,
    updateReportingManager: updateReportingManagerSlice,
    addHomeHealth: addHomeHealthReducer,
    updateHomeHealths: updateHomeHealthsReducer,
    cloneHomeHealth: cloneHomeHealthReducer,
    checkHomeHealthUserSchedule: checkHomeHealthUserScheduleSlice,
    cancelHomeHealth: cancelHomeHealthReducer,
    dataByPartnerOrderId: fetchDataByPartnerOrderIdReducer,
    user: userSliceReducer,
    facility: facilitySliceReducer,
    hospital: hospitalSliceReducer,
    lab: labSliceReducer,
    territory: territorySliceReducer,
    zone: zoneSliceReducer,
    deleteRoutineAction: deleteRoutineActionReducer,
    deleteStatAction: deleteStatActionReducer,
    assignFacilitiesToZone: assignFacilitiesToZoneReducer,
    zonesList: zonesListReducer,
    activeZonesList: activeZonesListReducer,
    activeZonesListWithDate: activeZonesListWithDateReducer,
    cancelStatOrder: cancelStatOrderReducer,
    uncancelHomeHealth: uncancelHomeHealthReducer,
    globalSearch: searchGlobalReducer,
    shiftTimings: shiftTimingsReducer,
    homeHealthScheduleFilter: homeHealthScheduleFilterReducer,
    homeHealthScheduleList: homeHealthScheduleListReducer,
    getManagerHH: getManagerReducer,
    getPhlebHH: getPhlebReducer,
    getRoutineManager: getRoutineManagerReducer,
    statGridData: statReducer,
    routineGridData: routineRedrawReducer,
    timedDrawGridData: timedDrawReducer,
    homeHealthGridData: homeHealthReducer,
    hospitalFilter: hospitalFilterReducer,
    orderExport: orderExportReducer,
    orderExportFileData: orderExportFileReducer,
    deleteExportFileData: deleteExportFileReducer,
    getAlloMap: getAlloMapReducer,
    addAlloMap: addAlloMapReducer,
    updateAlloMap: updateAlloMapReducer,
    fetchAlloMap: fetchAlloMapReducer
    // parentAccountNumber: parentAccountNumberReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger),
});
