import React from "react";
import {
  Aggregate,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Toolbar,
  Reorder,
  ExcelExport,
  Resize,
  InfiniteScroll
} from "@syncfusion/ej2-react-grids";
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DEFAULT_ORDER_SIZE, TYPE_HOMEHEALTH } from "../../app/utils/Constants";

class OrdersGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toolbar = ["Search", "ExcelExport", "ColumnChooser"];
    this.toolbarClick = (args) => {
      const { type, allGridData } = this.props;
      if (
        this[`customOrderGrid-${type}`] &&
        args?.item?.id === `customOrderGrid-${this.props.type}_excelexport`
      ) {
        this.props.performExport(this.props.orderType);
      }
    };
    this.sortingOptions = {
      columns: [{ field: "order_creation_datetime", direction: "Ascending" }]
    };
    this[`allData-${this.props.type}`] = [];
    this[`groupedCount-${this.props.type}`] = 0;
    this.triggerDataBound = false;
    this.gridElement = null; // For the main grid
    this.gridContentElement = null;
    this.state = {
      hasMounted: false
    }
  }

  componentDidMount() {
    const {type} = this.props;
    this.gridElement = this[`customOrderGrid-${this.props.type}`].element;
    this.gridContentElement = this.gridElement.querySelector('.e-gridcontent');
    if (this.gridContentElement) {
      this.gridContentElement.addEventListener('scroll', this.handleScroll);
      this.handleScroll();
    }
    if (this.props.isExportDisabled) {
      let gridObj = document.getElementById(`customOrderGrid-${type}`) && document.getElementById(`customOrderGrid-${type}`).ej2_instances && document.getElementById(`customOrderGrid-${type}`).ej2_instances[0];
      if (gridObj) {
        gridObj.toolbarModule.enableItems([`customOrderGrid-${type}_excelexport`], false);
      }
    } else {
      let gridObj = document.getElementById(`customOrderGrid-${type}`) && document.getElementById(`customOrderGrid-${type}`).ej2_instances && document.getElementById(`customOrderGrid-${type}`).ej2_instances[0];
      if (gridObj) {
        gridObj.toolbarModule.enableItems([`customOrderGrid-${type}_excelexport`], true);
      }
    }
    this.setState({ hasMounted: true });
  }

  handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = this.gridContentElement;

    const isScrollable = scrollHeight > clientHeight;
    const notAtBottom = scrollTop + clientHeight < scrollHeight;

    this.setState({
      showButton: isScrollable && notAtBottom,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { type } = this.props;
    // Update the grid data if the listData changes
    if (this[`customOrderGrid-${this.props.type}`] && (this.props.data.result !== prevProps.data.result)) {
      console.log('grid component dataSource update');
      this.triggerDataBound = true;
      // this[`allData-${this.props.type}`] = [...this[`customOrderGrid-${this.props.type}`].currentViewData, ...this.props.data.result];
      this[`customOrderGrid-${this.props.type}`].dataSource = { result: this.props.data.result, count: this.props.data.count };
      if (this.state.hasMounted) {
        console.log('mounted');
        this.setState({ hasMounted: false }, () => {
          // check if any filtering/grouping/searching/sorting applied

          let grid = this[`customOrderGrid-${type}`];
          if ((grid.filterSettings && grid.filterSettings && grid.filterSettings.columns && grid.filterSettings.columns.length) ||
            (grid.searchSettings && grid.searchSettings && grid.searchSettings.properties && grid.searchSettings.properties.key) ||
            (grid.sortSettings && grid.sortSettings.columns && grid.sortSettings.columns.length) ||
            (grid.groupSettings && grid.groupSettings.columns && grid.groupSettings.columns.length)) {

            const onlySortingIsApplied = (grid.sortSettings && grid.sortSettings.columns && grid.sortSettings.columns.length) &&
              (!grid.groupSettings || !grid.groupSettings.columns || !grid.groupSettings.columns.length) &&
              (!grid.searchSettings || !grid.searchSettings || !grid.searchSettings.properties || !grid.searchSettings.properties.key) &&
              (!grid.filterSettings || !grid.filterSettings || !grid.filterSettings.columns || !grid.filterSettings.columns.length);

            const state = { skip: 0, take: DEFAULT_ORDER_SIZE };
            let action = this[`customOrderGrid-${type}`].getDataModule().generateQuery();
            this.execute(state, action, true, onlySortingIsApplied, this.props.data.count, this.props.data.result);
          }
        })
      }
    }
    if (prevProps.isClicked !== this.props.isClicked && this.props.isClicked) {
      this.clearFilter();
    }
    if (prevProps.resetGridInternalFilters !== this.props.resetGridInternalFilters && this.props.resetGridInternalFilters) {
      this.clearFilter(false);
    }
    if (prevProps.clearRowSelection !== this.props.clearRowSelection) {
      this.updateRowSelection();
    }
    if (prevProps.isModalOpen !== this.props.isModalOpen && !this.props.isModalOpen) {
      this.clearSelectedRow();
    }
    if (this.props.isExportDisabled !== prevProps.isExportDisabled) {
      if (this.props.isExportDisabled) {
        let gridObj = document.getElementById(`customOrderGrid-${type}`) && document.getElementById(`customOrderGrid-${type}`).ej2_instances && document.getElementById(`customOrderGrid-${type}`).ej2_instances[0];
        if (gridObj) {
          gridObj.toolbarModule.enableItems([`customOrderGrid-${type}_excelexport`], false);
        }
      } else {
        let gridObj = document.getElementById(`customOrderGrid-${type}`) && document.getElementById(`customOrderGrid-${type}`).ej2_instances && document.getElementById(`customOrderGrid-${type}`).ej2_instances[0];
        if (gridObj) {
          gridObj.toolbarModule.enableItems([`customOrderGrid-${type}_excelexport`], true);
        }
      }
    }
  }
  componentWillUnmount() {
    if (this[`customOrderGrid-${this.props.type}`]) {
      this[`customOrderGrid-${this.props.type}`].destroy();
    }
  }
  updateRowSelection = () => {
    const { type, setClearRowSelection } = this.props;
    let persistedData = this[`customOrderGrid-${type}`].getPersistData()
      ? JSON.parse(this[`customOrderGrid-${type}`].getPersistData())
      : {};
    var value = window.localStorage.getItem(`gridgrid-component-${type}`); //"gridGrid" is component name + component id.
    var model = value ? { ...JSON.parse(value) } : {};
    model["selectedRowIndex"] = -1; // remove the column from localStorage
    model["pageSettings"] = {};
    model.searchSettings = {
      key: "",
      fields: [],
      operator: "contains",
      ignoreCase: true,
      ignoreAccent: false,
    };
    this[`customOrderGrid-${type}`].setProperties(
      JSON.stringify({ ...persistedData, ...model })
    );
    window.localStorage.setItem(
      `gridgrid-component-${type}`,
      JSON.stringify(model)
    );
    setClearRowSelection(false);
  };
  clickHandler = () => {
    const { type } = this.props;
    this[`customOrderGrid-${type}`].searchSettings.key = "";
    const element = this[`customOrderGrid-${type}`].element.querySelector(
      ".e-input-group.e-search .e-input"
    );
    element.value = "";
    var grid2 = this[`customOrderGrid-${type}`];
    if (
      grid2 &&
      grid2.element &&
      grid2.element
        .getElementsByClassName("e-search")[0]
        .classList.contains("clear")
    ) {
      const parent = document.getElementById(
        `customOrderGrid-${this.props.type}clear`
      );
      parent && parent.parentNode && parent.parentNode.removeChild(parent);
      grid2.element
        .getElementsByClassName("clear")[0]
        .classList.remove("clear");
      grid2.element
        .getElementsByClassName("e-search-icon")[0]
        .classList.remove("hide");
    }
  };
  created = (args) => {
    const { type } = this.props;
    var gridElement = this[`customOrderGrid-${type}`] && this[`customOrderGrid-${type}`].element;

    if (this[`customOrderGrid-${type}`]) {
      this[`customOrderGrid-${type}`].searchModule.hasNonNumericCharacters = function (searchKey) {
        return true;
      };
    }

    if (gridElement) {
      var grid2 = this[`customOrderGrid-${type}`];
      var emptyRow = grid2 && grid2.element.querySelector(".e-emptyrow");
      if (emptyRow) {
        emptyRow.cells[0].innerText = "No records to display.";
      }

      document &&
        document.getElementById(gridElement.id + "_searchbar") &&
        document
          .getElementById(gridElement.id + "_searchbar")
          .addEventListener("keyup", (e) => {
            this.setDebounce(e.target.value);
          });

      // wrapper for drop area
      let dropArea = gridElement
        ? gridElement.getElementsByClassName("e-groupdroparea")
        : [];
      let dropWrapper = gridElement
        ? gridElement.getElementsByClassName("drag-column-wrapper")
        : [];
      if (dropArea.length > 0 && !dropWrapper.length) {
        dropArea = dropArea[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "drag-column-wrapper";

        dropArea.parentNode.insertBefore(divWrapper, dropArea);
        divWrapper.appendChild(dropArea);
      }

      // add clear button in toolbar
      let rightToolbar = gridElement
        ? gridElement.getElementsByClassName("e-toolbar-left")
        : [];
      let clearButton = gridElement
        ? gridElement.getElementsByClassName("clear-internal-grid-button")
        : [];
      if (rightToolbar.length > 0 && !clearButton.length) {
        rightToolbar = rightToolbar[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "e-toolbar-item clear-internal-grid-button filter-reset-btn";
        divWrapper.title = "Clear Button";

        // Create a button element
        const newButton = document.createElement('button');
        newButton.title = `This resets Grid's internal filters (searching, column-level-filters, grouping, column selection)`; // Button text
        newButton.className = "primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary";
        newButton.onclick = function () {
          this.clearFilter(false); // Onclick functionality
        }.bind(this);

        const spanIcon = document.createElement('span');
        spanIcon.className = "reset-all reset-grid-filter pe-1";
        spanIcon.style.paddingTop = "1px";
        newButton.appendChild(spanIcon);

        const buttonText = document.createTextNode('Reset Grid Filters');
        newButton.appendChild(buttonText);

        divWrapper.appendChild(newButton);

        rightToolbar.appendChild(divWrapper);
        // divWrapper.appendChild(dropArea);
      }

      if (
        document.getElementById(`${gridElement.id}_searchbar`) &&
        document.getElementById(`${gridElement.id}_searchbar`).value
      ) {
        document.getElementsByClassName("e-search-icon") &&
          document.getElementsByClassName("e-search-icon")[0] &&
          document
            .getElementsByClassName("e-search-icon")[0]
            .classList.add("hide");
      }
    }
  };
  setDebounce = this.debounce((value) => {
    // perform programmatic search
    this[`customOrderGrid-${this.props.type}`].search(value);
  }, 1000); // set the debounce time, increase timeout value as per your need to avoid you mentioned flicker scenario.
  debounce(func, wait) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        func.apply(context, args);
      }, wait);
    };
  }
  dataBound = (args) => {
    console.log('databound');

    const { type } = this.props;
    const gridElement = this[`customOrderGrid-${type}`] && this[`customOrderGrid-${type}`].element;
    if (gridElement) {
      let dropArea = gridElement
        ? gridElement.getElementsByClassName("e-groupdroparea")
        : [];
      let dropWrapper = gridElement
        ? gridElement.getElementsByClassName("drag-column-wrapper")
        : [];
      if (dropArea.length > 0 && !dropWrapper.length) {
        dropArea = dropArea[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "drag-column-wrapper";

        dropArea.parentNode.insertBefore(divWrapper, dropArea);
        divWrapper.appendChild(dropArea);
      }
    }
    const grid = this[`customOrderGrid-${type}`];
    var grid2 = this[`customOrderGrid-${type}`];
    document &&
      grid2 &&
      document.getElementById(grid2.element.id + "_searchbar") &&
      document
        .getElementById(grid2.element.id + "_searchbar")
        .addEventListener("keyup", (event) => {
          if (!event.target.value) {
            if (
              grid2 &&
              grid2.element &&
              grid2.element.getElementsByClassName("e-search") &&
              grid2.element.getElementsByClassName("e-search")[0] &&
              grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.contains("clear")
            ) {
              const span = document.getElementsByClassName("e-clear-icon");
              while (span && span.length > 0 && span[0]) {
                grid2.element
                  .getElementsByClassName("clear")[0]
                  .removeChild(span[0]);
              }
              grid2.element
                .getElementsByClassName("clear")[0]
                .classList.remove("clear");
              grid2.element
                .getElementsByClassName("e-search-icon")[0]
                .classList.remove("hide");
            }
          } else {
            //  checks whether the cancel icon is already present or not
            if (
              grid2 &&
              grid2.element &&
              !grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.contains("clear")
            ) {
              var gridElement = this[`customOrderGrid-${type}`].element;
              if (
                grid2.element.getElementsByClassName(`e-sicon`) &&
                grid2.element.getElementsByClassName(`e-sicon`)[0]
              ) {
                grid2.element
                  .getElementsByClassName(`e-sicon`)[0]
                  .classList.add("hide");
              }
              var span = document.createElement("span");
              span.className = "e-clear-icon";
              span.id = gridElement.id + "clear";
              span.onclick = this.clickHandler.bind(this);
              grid2.element
                .getElementsByClassName("e-search")[0]
                .appendChild(span);
              grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.add("clear");
              grid2.element
                .getElementsByClassName("e-search-icon")[0]
                .classList.add("hide");
            }
          }
        });

    var emptyRow = grid2 && grid2.element.querySelector(".e-emptyrow");
    if (emptyRow) {
      emptyRow.cells[0].innerText = "No records to display";
    }
    // let cloned = grid.addOnPersist;
    // grid.addOnPersist = function (key) {
    //     key = key.filter((item) => item !== "columns");
    //     return cloned.call(this, key);
    // };

    // let grid = this[`customOrderGrid-${type}`];
    // if (this.triggerDataBound) {
    //   if (grid) {
    //     const query = new Query();
    //     if ((grid.filterSettings && grid.filterSettings && grid.filterSettings.columns && grid.filterSettings.columns.length) ||
    //       (grid.searchSettings && grid.searchSettings && grid.searchSettings.properties && grid.searchSettings.properties.key)) {
    //       // filtering
    //       if (grid.filterSettings && grid.filterSettings && grid.filterSettings.columns && grid.filterSettings.columns.length) {
    //         this.applyFiltering(query, grid.filterSettings);
    //       }
    //       // searching
    //       if (grid.searchSettings && grid.searchSettings && grid.searchSettings.properties && grid.searchSettings.properties.key) {
    //         this.applySearching(query, [grid.searchSettings]);
    //       }
    //       const dataManager = new DataManager(this.props.allGridData);
    //       const result = dataManager.executeLocal(query);
    //       const localData = { result: result, count: this.props.data.count };
    //       this[`customOrderGrid-${type}`].dataSource = localData;
    //     }
    //   }
    //   this.triggerDataBound = false;
    // }
  };
  handleAction = (args) => {
    const { type, allGridData, setOrdersRowCount } = this.props;
    if (args.requestType == "filterafteropen") {
      let grid =
        this[`customOrderGrid-${type}`].element.getElementsByClassName(
          `e-popup-open`
        );
      if (grid && grid.length) {
        grid[0].ej2_instances[0].header = args.filterModel.options.displayName;
      }
    }
    if (args.requestType == 'filterchoicerequest') {
      // hide 'Add current selection to filter' option
      var addCurrent = args.filterModel.dlg.querySelector('.e-add-current');

      if (addCurrent) {
        addCurrent.closest('.e-ftrchk').style.display = 'none';
      }
    }
    if (args.requestType === 'searching') {
      let grid = this[`customOrderGrid-${type}`];
      grid.toolbarModule.enableItems([grid.element.id + '_searchbar'], true);
    }
    let grid = this[`customOrderGrid-${type}`];
    if (grid) {
      if (grid.groupSettings.columns.length && !this[`groupedCount-${this.props.type}`]) {
        let totalGroupedCount = 0;
        grid.currentViewData.forEach(cData => {
          totalGroupedCount += cData.count;
        });
        this[`groupedCount-${this.props.type}`] = totalGroupedCount; // set group row count when group or ungroup column.
      }
      let rowCount = args.requestType === 'infiniteScroll' ? allGridData.length : grid.groupSettings.columns.length ? grid.currentViewData?.records?.length : grid.currentViewData.length;
      setOrdersRowCount(rowCount);
      
      if (!grid.dataSource || !grid.dataSource.result || !grid.dataSource.result.length) {
        let gridObj = document.getElementById(`customOrderGrid-${type}`) && document.getElementById(`customOrderGrid-${type}`).ej2_instances && document.getElementById(`customOrderGrid-${type}`).ej2_instances[0];
        if (gridObj) {
          gridObj.toolbarModule.enableItems([`customOrderGrid-${type}_excelexport`], false);
        }
      } else {
        let gridObj = document.getElementById(`customOrderGrid-${type}`) && document.getElementById(`customOrderGrid-${type}`).ej2_instances && document.getElementById(`customOrderGrid-${type}`).ej2_instances[0];
        if (gridObj) {
          gridObj.toolbarModule.enableItems([`customOrderGrid-${type}_excelexport`], true);
        }
      }
    }
  };
  actionBegin = (args) => {
    const {type} = this.props;
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = 100000;
    }
    if (args.requestType === 'grouping' || args.requestType === 'ungrouping') {
      this[`groupedCount-${this.props.type}`] = 0; // reset grouping count when group or ungroup column.
    }
    if (args.requestType === 'searching') {
      let grid = this[`customOrderGrid-${type}`];
      grid.toolbarModule.enableItems([grid.element.id + '_searchbar'], false);
    }
  };
  clearFilter = (shouldCallCallback = true, shouldClearSorting = true) => {
    const { type, callback } = this.props;
    let grid = this[`customOrderGrid-${type}`];
    if (grid) {
      shouldCallCallback && callback();
      // console.log(grid.getPersistData(), 'getPersistData');
      // var value = window.localStorage.getItem(`gridgrid-component-${type}`); //"gridGrid" is component name + component id.
      // var model = JSON.parse(value);
      // model["groupSettings"] = {};    // remove the column from localStorage
      // model["sortSettings"] = {};
      // window.localStorage.setItem(`gridgrid-component-${type}`, JSON.stringify(model));
      // grid.enablePersistence = false;
      // localStorage.setItem(`gridgrid-component-${type}`, "");
      // localStorage.setItem(`toolbargrid-component-${type}_toolbarItems`, "");
      grid.clearFiltering();
      shouldClearSorting && grid.clearSorting();
      grid.searchSettings.key = "";
      // const existingColumns = grid.columns;
      // grid.columns = [];
      // [...columns, ...newColumns].forEach(col => {
      //   const tempCol = existingColumns.find((column) => column.field === col.field) || {};
      //   const obj = { ...col, ...tempCol, uid: `grid-column${grid.columns.length}`, visible: tempCol.visible || false };
      //   grid.columns.push(obj);
      // })
      // grid.refreshColumns();
      // grid.columns = iterateExtend(this[`gridColumns-${type}`]);
      setTimeout(() => {
        grid.clearGrouping();
      }, 1);
    }
  };
  rowSelected = (e) => {
    const { homeHealthListForModal = [], type } = this.props;
    if (e.isInteracted) {
      if (this.props.type === "user") {
        this.props.editModal(e);
      } else if (type === TYPE_HOMEHEALTH) {
        const dataId = e.data && e.data.id;
        let data = {};
        homeHealthListForModal.forEach(dd => {
          if (dd.id == dataId) {
            data = dd;
          }
        })
        this.props.editModal(true, ((data && Object.keys(data).length > 0) ? { data: data } : e), "view");
      } else {
        this.props.editModal(true, e, "view");
      }
    }
  };
  clearSelectedRow = () => {
    let grid = this[`customOrderGrid-${this.props.type}`];
    grid && grid.clearRowSelection()
  }

  dataStateChange = (state) => {
    const { type, allGridData } = this.props;
    let action = this[`customOrderGrid-${type}`].getDataModule().generateQuery();

    if (
      state.action &&
      (state.action.requestType == 'filterchoicerequest' ||
        state.action.requestType == 'stringfilterrequest')
    ) {
      // below code will be executed when
      // 1. Typing the value in checkbox Filter Dialog
      // 2. Opening the checkbox Filter dialog

      state.dataSource(allGridData);
    } else if (
      state.action &&
      state.action.requestType === 'filterSearchBegin'
    ) {
      if (state.where) {
        //  Searching the value in checkbox filter dialog
        let query = state.where[0];
        const dataManager = new DataManager(allGridData);
        const result = dataManager.executeLocal(
          new Query().where(query.field, query.operator, query.value, query.ignoreCase, query.ignoreAccent)
        );
        state.dataSource(result);
      } else if (state.search) {
        let query = state.search[0];
        const dataManager = new DataManager(allGridData);
        let newData = [];
        query.fields.forEach((field) => {
          const result = dataManager.executeLocal(
            new Query().where(field, query.operator, query.key, query.ignoreCase)
          );
          newData = [...newData, ...result];
        });

        state.dataSource(newData);
      } else {
        state.dataSource(allGridData);
      }
    } else {
      this.execute(state, action);
    }
  }

  execute(state, action, isMounted = false, onlySortingIsApplied = false) {
    const { type, stateDispatch, getGridData } = this.props;
    //Here we check whether the requestType is sorting or paging on we call the API
    const query = new Query();
    // filtering
    if (state.where || action.queries) {
      this.applyFiltering(query, action.queries);
    }

    // initial sorting
    if (state.sorted) {
      if (state.sorted.length) {
        this.applySorting(query, state.sorted)
      } else if (state.sorted.columns.length) {
        this.applySorting(query, state.sorted.columns)
      }
    }
    // search
    if (state.search || action.queries) {
      this.applySearching(query, state.search, action.queries);
    }
    // grouping
    if (state.group) {
      if (state.group.length) {
        this.applyGrouping(query, state.group)
      } else if (state.group.columns.length) {
        this.applyGrouping(query, state.group.columns)
      }
    }
    // lazy load grouping
    // paging
    this.applyPaging(query, state);
    // To get the count of the data
    query.isCountRequired = true;

    console.log(state, query, 'llll');

    if ((!state.action ? isMounted ? onlySortingIsApplied : true : ((state.action?.requestType === 'sorting' && !query.queries.some(q => q.fn === "onWhere" || q.fn === "onSearch")) || state.action?.requestType === 'infiniteScroll'))
    ) {
      const resetAllGridData = state.action ? state.action?.requestType === 'sorting' ? true : state.action?.requestType === 'infiniteScroll' ? false : true : (isMounted && onlySortingIsApplied);
      if (resetAllGridData) {
        this.clearFilter(false, false);
      }
      let newState = { ...state };
      if (!state.action && isMounted && onlySortingIsApplied) {
        let sortedArr = action.queries.filter(q => q.fn === "onSortBy");
        let sortedData = { name: sortedArr[0]?.e?.fieldName, direction: sortedArr[0]?.e?.direction };
        newState.sorted = [sortedData];
      }
      stateDispatch(getGridData, newState, resetAllGridData);
    } else {
      // this case is only for the combination of actions which includes sorting - e.g. filter+sorting removes sorting and keeps filter settings
      if (isMounted) {
        let grid = this[`customOrderGrid-${type}`];
        if (grid.sortSettings && grid.sortSettings.columns && grid.sortSettings.columns.length) {
          query.queries = query.queries.filter(q => q.fn !== "onSortBy");
          grid.clearSorting();
        }
      } 
      // else {
        // Wrapping the local operation in a Promise to ensure consistency
        new Promise((resolve) => {
          const localData = this.localActionsHandler(state, query, this.props.data.count, [], isMounted);
          this[`customOrderGrid-${type}`].dataSource = localData;
          resolve(localData);
        });
      // }
    }
  }

  localActionsHandler(state, query, totalCount, currentFetechedRecords, isMounted = false) {
    const { type, data, allGridData } = this.props;
    if (
      state &&
      state.action &&
      (state.action.requestType === 'infiniteScroll' ||
        state.action.requestType === 'initialFetch')
    ) {
      // Return current fetched records for infinite scroll or initial fetch
      return { result: currentFetechedRecords, count: totalCount };
    } else {
      const dataManager = new DataManager(allGridData);
      query.queries = query.queries.filter(q => q.fn !== "onPage");

      // if (isMounted) {
      //   let grid = this[`customOrderGrid-${type}`];
      //   if (grid.sortSettings && grid.sortSettings.columns && grid.sortSettings.columns.length) {
      //     query.queries = query.queries.filter(q => q.fn !== "onSortBy");
      //     grid.clearSorting();
      //   }
      // }

      const result = dataManager.executeLocal(query);
      let finalCount = result.result ? result.result.length : result.length;

      // when grouping exists
      if (query.queries && query.queries.some(q => q.fn == "onGroup")) {
        finalCount = result.result?.records.length;
      }

      // when clearing any filter
      if (state.action && ((state.action.requestType === "searching" && !state.action.searchString)
        || (state.action.requestType === "filtering" && !state.action.currentFilteringColumn) || (state.action.requestType === "ungrouping") ||
        (state.action.requestType === "refresh"))) {
        finalCount = totalCount
      }
      return { result: result.result ? result.result : result, count: finalCount };
    }
  }

  applyFiltering(query, filter) {
    // Check if filter columns are specified
    if (filter.columns && filter.columns.length) {
      // Apply filtering for each specified column
      for (let i = 0; i < filter.columns.length; i++) {
        const field = filter.columns[i].field;
        const operator = filter.columns[i].operator;
        const value = filter.columns[i].value;
        const ignoreCase = filter.columns[i].ignoreCase;
        const ignoreAccent = filter.columns[i].ignoreAccent;
        query.where(field, operator, value, ignoreCase, ignoreAccent);
      }
    }

    else {
      // Apply filtering based on direct filter conditions
      for (let i = 0; i < filter.length; i++) {
        const { fn, e } = filter[i];
        if (fn === 'onWhere') {
          query.where(e);
        }
      }
    }
  }

  applySorting(query, sorted) {
    // Check if sorting data is available
    if (sorted && sorted.length > 0) {
      // Iterate through each sorting info
      sorted.forEach(sort => {
        // Get the sort field name either by name or field
        const sortField = sort.name || sort.field;

        // Perform sort operation using the query based on the field name and direction
        query.sortBy(sortField, sort.direction);
      });
    }
  }

  applySearching(query, search, actionQueries) {
    // Check if a search operation is requested
    if (search && search.length > 0) {
      // Extract the search key and fields from the search array
      const { fields, key, operator, ignoreCase, ignoreAccent } = search[0];
      // perform search operation using the field and key on the query
      query.search(key, fields, operator, ignoreCase, ignoreAccent);
    } else if (actionQueries && actionQueries.length > 0) {
      actionQueries.forEach((actionQuery) => {
        if (actionQuery && actionQuery.e) {
          const key = actionQuery.e.searchKey; // Check if searchKey exists
          const fields = actionQuery.e.fieldNames; // Check if fieldNames exists
          const operator = actionQuery.e.operator;
          const ignoreCase = actionQuery.e.ignoreCase;
          const ignoreAccent = actionQuery.e.ignoreAccent;

          if (fields) {
            // Perform search operation using the field and key on the query
            query.search(key, fields, operator, ignoreCase, ignoreAccent);
          }
        }
      });
    }
  }

  applyGrouping(query, group) {
    // Check if sorting data is available
    if (group.length > 0) {
      // Iterate through each group info
      group.forEach((column) => {
        // perform group operation using the column on the query
        query.group(column);
      });
    }
  }

  applyPaging(query, state) {
    // Check if both 'take' and 'skip' values are available
    if (state.take && state.skip) {
      // Calculate pageSkip and pageTake values to get pageIndex and pageSize
      const pageSkip = state.skip / state.take + 1;
      const pageTake = state.take;
      query.page(pageSkip, pageTake);
    }
    // If if only 'take' is available and 'skip' is 0, apply paging for the first page.
    else if (state.skip === 0 && state.take) {
      query.page(1, state.take);
    }
  }

  scrollToBottom = () => {
    let gridContentDiv = this[`customOrderGrid-${this.props.type}`].element.getElementsByClassName('e-content')[0];
    gridContentDiv && gridContentDiv.scrollTo(0, gridContentDiv.scrollHeight);
  };

  render() {
    let {
      data = [],
      columns = [],
      type,
      ordersRowCount,
      showButton
    } = this.props;
    console.log(data, 'gridd')
    return (
      <div className={"ad-grid-wrapper grid-common-wrapper"}>
        <GridComponent
          ref={(comp) => {
            this[`customOrderGrid-${type}`] = comp;
          }}
          id={"customOrderGrid-" + type}
          //   dataSource={data}
          toolbar={this.toolbar}
          toolbarClick={this.toolbarClick}
          created={this.created}
          dataBound={this.dataBound}
          actionComplete={this.handleAction}
          actionBegin={this.actionBegin}
          rowSelected={this.rowSelected}
          dataStateChange={this.dataStateChange}
          // sortSettings={this.sortingOptions}
          groupSettings={{ showDropArea: true }}
          searchSettings={{ operator: 'contains' }}
          filterSettings={{ type: "CheckBox", enableCaseSensitivity: false }}
          height={150}
          enableInfiniteScrolling={true}
          infiniteScrollSettings={{ initialBlocks: 1 }}
          allowSorting={true}
          showColumnChooser={true}
          allowFiltering={true}
          allowExcelExport={true}
          allowResizing={true}
          allowGrouping={true}
          allowReordering={true}
          pageSettings={{ pageSize: DEFAULT_ORDER_SIZE }}
          enablePersistence={true}
          delayUpdate
        >
          <Inject
            services={[ExcelExport,
              Reorder,
              Aggregate,
              Group,
              Sort,
              Filter,
              Aggregate,
              Toolbar,
              ColumnChooser,
              Resize,
              Page,
              InfiniteScroll]}
          />

          <ColumnsDirective>
            {columns.map((column, index) => {
              return (
                <ColumnDirective
                  field={column.field}
                  headerText={column.headerText}
                  width={column.width}
                  template={column.template || null}
                  visible={column.visible}
                  key={index}
                  filter={{ operators: "contains" }}
                />
              );
            })}
          </ColumnsDirective>
        </GridComponent>
        {true && <div className="back-to-top-wrapper">
          <button
            className="back-to-top justify-content-center align-items-center"
            onClick={this.scrollToBottom}
          >
            <span className="mob-back"></span>
          </button>
        </div>}
        <div className="items-count-wrap">{ordersRowCount} {`of ${data.count || 0} ${data.count > 1 ? "items" : "item"}`}</div>
      </div>
    );
  }
}
export default OrdersGrid;
