import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FACILITYLIST, HOSPITALLIST, LABLIST, ROLE_COURIER, TERRITORYLIST, USERLIST, ZONELIST, adminClass } from "../../app/utils/Constants";
import InnerHeader from "../../components/layouts/InnerHeader";
import { filterList, getFilter } from "./AddUser/filterSlice";
import Profile from "./Profile";
import {
  clearUpdateUserResponse,
  updateUser,
  updateUserList,
} from "../LandingPage/UpdateUser/updateUserSlice";
import { getUsers, userLoader, usersList } from "./userSlice";
import {
  getProfile,
  profileList,
  profileLoader,
} from "../LandingPage/Profile/profileSlice";
import LottieAnimation from "../../components/layouts/LottieAnimation";
import {
  getFacilities,
  facilitiesLoader,
  facilitiesList,
} from "../LandingPage/AddUser/facilitiesSlice";
import FacilityAddEdit from "./FacilityAddEdit/FacilityAddEdit";
import {
  clearUpdateFacilityResponse,
  updateFacility,
  updateFacilityList,
} from "./FacilityAddEdit/UpdateFacilitySlice";
import {
  addFacilities,
  addFacilitiesList,
  clearAddFacilityResponse,
} from "./FacilityAddEdit/AddFacilitySlice";
import { clearAddUserResponse } from "./AddUser/addUserSlice";
import { tokenList } from "../accounts/Login/tokenSlice";
import { getHospitals, hospitalsList, hospitalsLoader } from "./HospitalAddEdit/HospitalsSlice";
import HospitalAddEdit from "./HospitalAddEdit/HospitalAddEdit";
import { clearUpdateHospitalResponse, updateHospital, updateHospitalList } from "./HospitalAddEdit/UpdateHospitalSlice";
import { addHospitals, addHospitalsList, clearAddHospitalResponse } from "./HospitalAddEdit/AddHospitalSlice";
import { getLabs, labsList, labsLoader } from "./LabAddEdit/LabsSlice";
import { clearUpdateLabResponse, updateLab, updateLabList } from "./LabAddEdit/UpdateLabSlice";
import { addLabs, addLabsList, clearAddLabResponse } from "./LabAddEdit/AddLabSlice";
import LabAddEdit from "./LabAddEdit/LabAddEdit";
import { labTypes } from "./LabAddEdit/LabDataSlice";
import { assignReportingManager, clearUpdateReportingManagerVal, updateReportingManagerVal } from "./assignReportingManagerSlice";
import { getTerritories, territoriesList, territoriesLoader } from "./TerritoryAddEdit/TerritoriesSlice";
import TerritoryAddEdit from "./TerritoryAddEdit/TerritoryAddEdit";
import { clearUpdateTerritoryResponse, updateTerritory, updateTerritoryList } from "./TerritoryAddEdit/UpdateTerritorySlice";
import { addTerritories, addTerritoriesList, clearAddTerritoryResponse } from "./TerritoryAddEdit/AddTerritorySlice";
import ahaApi from "../../app/services/aha-api";
import { getZones, zonesList, zonesLoader } from "./ZoneAddEdit/ZonesSlice";
import { clearUpdateZoneResponse, updateZone, updateZoneList } from "./ZoneAddEdit/UpdateZoneSlice";
import { addZones, addZonesList, clearAddZoneResponse } from "./ZoneAddEdit/AddZoneSlice";
import ZoneAddEdit from "./ZoneAddEdit/ZoneAddEdit";
import { getUser, setUserList, user, userObjLoader } from "./Profile/userSlice";
import { facility, facilityObjLoader, getFacility, setFacilityList } from "./FacilityAddEdit/facilitySlice";
import { getHospital, hospital, hospitalObjLoader, setHospitalList } from "./HospitalAddEdit/hospitalSlice";
import { getLab, lab, labObjLoader, setLabList } from "./LabAddEdit/labSlice";
import { getTerritory, setTerritoryList, territoryObj, territoryObjLoader } from "./TerritoryAddEdit/territorySlice";
import { getZone, setZoneList, zoneObj, zoneObjLoader } from "./ZoneAddEdit/zoneSlice";
import { assignFacilitiesToZone, assignFacilitiesToZoneVal, clearUpdateFacilityToZoneVal } from "./assignFacilitiesToZoneSlice";
import { activeZonesList, getActiveZones } from "./activeZonesSlice";
import { useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import ToastContent from "../../components/layouts/ToastContent";
import { getHospitalFilter, hospitalFilterList } from "./HospitalAddEdit/hospitalFilterSlice";

export default function Home(props) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramId = searchParams.get("id");

  const history = useHistory();

  const users = useSelector(usersList);
  const updateList = useSelector(updateUserList);
  const facilities = useSelector(facilitiesList);
  const faciLoading = useSelector(facilitiesLoader);
  const profile = useSelector(profileList);
  const profileLoading = useSelector(profileLoader);
  const loading = useSelector(userLoader);
  const updateReportingManagerResponse = useSelector(updateReportingManagerVal);
  const assignFacilitiesToZoneResponse = useSelector(assignFacilitiesToZoneVal);
  const [inactive_user, setInactive] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const filter = useSelector(filterList);
  const hospitalFilterListData = useSelector(hospitalFilterList);
  console.log(hospitalFilterListData, 'hospitalFilterListData', hospitalFilterList);
  
  const activeZones = useSelector(activeZonesList);
  const [editData, setData] = React.useState({});
  const [lottie, setLottie] = React.useState(false);
  const [error, setError] = React.useState("");
  const [page, setPage] = React.useState("profile");
  const [full_name, setName] = React.useState("");
  const [color, setColor] = React.useState("");
  const [profileData, setProData] = React.useState([]);
  const [editFacility, setEditFacility] = React.useState(false);
  const userResponse = useSelector(user);
  const userObjLoading = useSelector(userObjLoader);

  //facility
  const [facilityData, setFacilityData] = React.useState({});
  const updateFList = useSelector(updateFacilityList);
  const addFacility = useSelector(addFacilitiesList);
  const facilityResponse = useSelector(facility);
  const facilityObjLoading = useSelector(facilityObjLoader);
  const [statusModalType, setStatusModalType] = React.useState({ value: false });
  const [userStatusModalType, setUserStatusModalType] = React.useState({ value: false });

  //hospital
  const hospitals = useSelector(hospitalsList);
  const hospitalLoader = useSelector(hospitalsLoader);
  const updateHospitalListResponse = useSelector(updateHospitalList);
  const addHospitalResponse = useSelector(addHospitalsList);
  const hospitalResponse = useSelector(hospital);
  const hospitalObjLoading = useSelector(hospitalObjLoader);
  const [hospitalData, setHospitalData] = React.useState({});

  //lab
  const labs = useSelector(labsList);
  const labLoader = useSelector(labsLoader);
  const updateLabListResponse = useSelector(updateLabList);
  const addLabResponse = useSelector(addLabsList);
  const labResponse = useSelector(lab);
  const labObjLoading = useSelector(labObjLoader);
  const [labData, setLabData] = React.useState({});
  const labDataResponse = useSelector(labTypes) || [];

  //territory
  const territories = useSelector(territoriesList);
  const territoryLoader = useSelector(territoriesLoader);
  const updateTerritoryListResponse = useSelector(updateTerritoryList);
  const addTerritoryResponse = useSelector(addTerritoriesList);
  const territoryResponse = useSelector(territoryObj);
  const territoryObjLoading = useSelector(territoryObjLoader);
  const [territoryData, setTerritoryData] = React.useState({});

  //zone
  const zones = useSelector(zonesList);
  const zoneLoader = useSelector(zonesLoader);
  const updateZoneListResponse = useSelector(updateZoneList);
  const addZoneResponse = useSelector(addZonesList);
  const zoneResponse = useSelector(zoneObj);
  const zoneObjLoading = useSelector(zoneObjLoader);
  const [zoneData, setZoneData] = React.useState({});
  const [zoneInactive, setZoneInactive] = React.useState(false);
  const [facilityZonesState, setFacilityZonesState] = React.useState({
    value: false,
    searchText: "", selectedFacilities: {},
    selectedZone: {}
  });
  const [statusModalTypeZone, setStatusModalTypeZone] = React.useState({ value: false });


  const [facilityInactive, setFacilityInactive] = React.useState(false);
  const [labInactive, setLabInactive] = React.useState(false);
  const [hospitalInactive, setHospitalInactive] = React.useState(false);

  const [userReporteesState, setUserReporteesState] = React.useState({
    value: false,
    searchText: "", selectedReportees: {},
    selectedManager: {}
  });

  const facilityList = facilities && facilities.content;
  const hospitalList = hospitals && hospitals.content;
  const labList = labs && labs.content;
  const territoryList = territories && territories.content;
  const zoneList = zones && zones.content;

  let { content: data = [] } = users || {};
  data = [...data].sort((a, b) => a.full_name.localeCompare(b.full_name))

  const job_title =
    filter && filter && filter.content && filter.content.job_title;
  const roles = filter && filter && filter.content && filter.content.roles;
  let region = filter && filter && filter.content && filter.content.region;
  let territory =
    filter && filter && filter.content && filter.content.territory;
  let timezone_ids =
    filter && filter && filter.content && filter.content.timezone_ids;
  timezone_ids = timezone_ids ? timezone_ids.filter(tz => tz.isVisible) : [];
  let states = filter && filter && filter.content && filter.content.states;
  let zone = activeZones ? activeZones.content || [] : [];
  let facility_type =
    filter && filter && filter.content && filter.content.facility_type;
  let days = filter && filter && filter.content && filter.content.days;
  let facilityServiceType =
    filter && filter && filter.content && filter.content.facility_service_type;
  const serviceStopDays =
    filter && filter && filter.content && filter.content.service_stop_days;
  let hospital_type =
  hospitalFilterListData ? hospitalFilterListData.content ? hospitalFilterListData.content.hospital_type || [] : [] : [];
  hospital_type = hospital_type.map(data => {return {...data, label: data.type_label, value: data.id }})

  let hospital_timezone_ids =
  hospitalFilterListData ? hospitalFilterListData.content ? hospitalFilterListData.content.timezone_ids || [] : [] : [];
  hospital_timezone_ids = hospital_timezone_ids ? hospital_timezone_ids.filter(tz => tz.isVisible) : [];

  let partners =
  hospitalFilterListData ? hospitalFilterListData.content ? hospitalFilterListData.content.partner || [] : [] : [];
  partners = partners.map(pt => {return {...pt, label: pt.partner_name, value: pt.id}})


  const labTypesData = labDataResponse ? labDataResponse.content ? (labDataResponse.content.lab_type || []) : [] : []

  let ab =
    region &&
    region.map((val, i) => {
      return { ...val, hasTrue: true };
    });
  let bc = ab && ab.concat(territory && territory);

  let tabValue = window.location.pathname.startsWith(USERLIST) ? "manageUsers" : window.location.pathname.startsWith(FACILITYLIST) ? "manageCare" :
    window.location.pathname.startsWith(LABLIST) ? "manageLabs" : window.location.pathname.startsWith(HOSPITALLIST) ? "manageHospitals" : window.location.pathname === TERRITORYLIST ? "manageTerritories" : window.location.pathname === ZONELIST ? "manageZones" : "";

  let tabRef = React.useRef({});
  tabRef.current = tabValue;

  React.useEffect(() => {
    document.documentElement.classList.add(adminClass);
    dispatch(getProfile());
    // dispatch(getFacilities());
  }, [window.location.pathname]);

  React.useEffect(() => {
    if (profile && profile.status === "SUCCESS") {
      if (profile && profile.content) {
        setProData(profile && profile.content && profile.content.permission);
        const userPermission =
          profile &&
          profile.content &&
          profile.content.permission &&
          profile.content.permission.filter((val, i) => {
            return val.page === "users";
          });
        const uPer =
          userPermission && userPermission[0] && userPermission[0].access;

        const list =
          uPer && uPer.find((item) => item === "list") === "list"
            ? true
            : false;
        const facilityPermission =
          profile &&
          profile.content &&
          profile.content.permission &&
          profile.content.permission.filter((val, i) => {
            return val.page === "facilities";
          });
        const fPer =
          facilityPermission &&
          facilityPermission[0] &&
          facilityPermission[0].access;
        const update =
          fPer && fPer.find((item) => item === "update") === "update"
            ? true
            : false;
        const flist =
          fPer && fPer.find((item) => item === "list") === "list"
            ? true
            : false;
        setEditFacility(update);
        const filter_list =
          uPer && uPer.find((item) => item === "filter-list") === "filter-list"
            ? true
            : false;
        localStorage.setItem(
          "userDetail",
          JSON.stringify(profile && profile.content)
        );
        if (filter_list) {
          dispatch(getFilter());
          dispatch(getActiveZones());
        }

        if (tabValue === "manageHospitals") {
          const hospitalPermission = profile.content.permission.filter(
            (perm) => perm.page === "hospitals"
          );
          const hospitalAccess = hospitalPermission && hospitalPermission[0] && hospitalPermission[0].access;
          const hospitalFilterList =
          hospitalAccess && hospitalAccess.find((item) => item === "filter-list") === "filter-list"
            ? true
            : false;
          if (hospitalFilterList) {
            dispatch(getHospitalFilter());
          }
        }
        setName(profile && profile.content && profile.content.name);
        setColor(profile && profile.content && profile.content.icon_color_code);
        // if (facility_list) dispatch(getFacilities(status));
      }
    }
  }, [profile]);

  React.useEffect(() => {
    if (userResponse && userResponse.status === "SUCCESS" && userResponse.content) {
      setData(userResponse.content || {});
    } else if (userResponse && userResponse.status === "ERROR" && userResponse.message) {
      handleClose();
      if (paramId) {
        history.replace(USERLIST, { shallow: true });
      }
      setTimeout(() => {
        toast(
          (t) => (
            <ToastContent type="error" message={userResponse.message} close={() => toast.remove()} />
          ),
          {
            duration: Infinity
          }
        );
      }, 1)
    }
  }, [userResponse]);

  React.useEffect(() => {
    if (facilityResponse && facilityResponse.status === "SUCCESS" && facilityResponse.content) {
      setFacilityData({ isOpen: true, mode: "view", data: facilityResponse.content || {} });
    } else if (facilityResponse && facilityResponse.status === "ERROR" && facilityResponse.message) {
      handleFacilityError("create");
      editFacilityModal(false);
      if (paramId) {
        history.replace(FACILITYLIST, { shallow: true });
      }
      setTimeout(() => {
        toast(
          (t) => (
            <ToastContent type="error" message={facilityResponse.message} close={() => toast.remove()} />
          ),
          {
            duration: Infinity
          }
        );
      }, 1)
    }
  }, [facilityResponse]);

  React.useEffect(() => {
    if (hospitalResponse && hospitalResponse.status === "SUCCESS" && hospitalResponse.content) {
      setHospitalData({ isOpen: true, mode: "view", data: hospitalResponse.content || {} });
    } else if (hospitalResponse && hospitalResponse.status === "ERROR" && hospitalResponse.message) {
      handleHospitalError();
      toggleHospitalModal(false);
      if (paramId) {
        history.replace(HOSPITALLIST, { shallow: true });
      }
      setTimeout(() => {
        toast(
          (t) => (
            <ToastContent type="error" message={hospitalResponse.message} close={() => toast.remove()} />
          ),
          {
            duration: Infinity
          }
        );
      }, 1)
    }
  }, [hospitalResponse]);

  React.useEffect(() => {
    if (labResponse && labResponse.status === "SUCCESS" && labResponse.content) {
      setLabData({ isOpen: true, mode: "view", data: labResponse.content || {} });
    } else if (labResponse && labResponse.status === "ERROR" && labResponse.message) {
      handleLabError();
      toggleLabModal(false);
      if (paramId) {
        history.replace(LABLIST, { shallow: true });
      }
      setTimeout(() => {
        toast(
          (t) => (
            <ToastContent type="error" message={labResponse.message} close={() => toast.remove()} />
          ),
          {
            duration: Infinity
          }
        );
      }, 1)
    }
  }, [labResponse]);

  React.useEffect(() => {
    if (territoryResponse && territoryResponse.status === "SUCCESS" && territoryResponse.content) {
      setTerritoryData({ isOpen: true, mode: "view", data: territoryResponse.content || {} });
    } else if (territoryResponse && territoryResponse.status === "ERROR" && territoryResponse.message) {
      handleTerritoryError();
      toggleTerritoryModal(false);
      setTimeout(() => {
        toast(
          (t) => (
            <ToastContent type="error" message={territoryResponse.message} close={() => toast.remove()} />
          ),
          {
            duration: Infinity
          }
        );
      }, 1)
    }
  }, [territoryResponse]);

  React.useEffect(() => {
    if (zoneResponse && zoneResponse.status === "SUCCESS" && zoneResponse.content) {
      setZoneData({ isOpen: true, mode: "view", data: zoneResponse.content || {} });
    } else if (zoneResponse && zoneResponse.status === "ERROR" && zoneResponse.message) {
      handleZoneError();
      toggleZoneModal(false);
      setTimeout(() => {
        toast(
          (t) => (
            <ToastContent type="error" message={zoneResponse.message} close={() => toast.remove()} />
          ),
          {
            duration: Infinity
          }
        );
      }, 1)
    }
  }, [zoneResponse]);

  React.useEffect(() => {
    if (updateList && updateList.status === "SUCCESS") {
      setData({});
      setLottie(true);
      handleClose();
      dispatch(getProfile());
      dispatch(getUsers(false));
      setError("");
      setPage("profile");
      setUserStatusModalType({ value: false });
      setUserReporteesState({ value: false })
      setTimeout(() => setLottie(false), 2000);
    } else if (updateList && updateList.message_code === "EVENT_DELETE_WARN") {
      // setPage("edit");
      const messageString = updateList.error_content || {};
      const { event_has_facility = [], event_has_zone = [], user_has_home_health_schedule = [], user_has_stat_schedule = [] } = messageString;
      setUserStatusModalType({
        value: true, status: "confirmation", type: "updateUser", title: updateList.message || "Are you sure you want to continue?",
        content: <ul>{event_has_facility && [...event_has_facility].map(message => {
          return (
            <li>{message}</li>
          )
        })}
          {event_has_zone && event_has_zone.length > 0 && <li><hr /></li>}
          {event_has_zone && [...event_has_zone].map(message => {
            return (
              <li>{message}</li>
            )
          })}
          {user_has_home_health_schedule && user_has_home_health_schedule.length > 0 && <li><hr /></li>}
          {user_has_home_health_schedule && [...user_has_home_health_schedule].map(message => {
            return (
              <li>{message}</li>
            )
          })}
          {user_has_stat_schedule && user_has_stat_schedule.length > 0 && <li><hr /></li>}
          {user_has_stat_schedule && [...user_has_stat_schedule].map(message => {
            return (
              <li>{message}</li>
            )
          })}
        </ul>
      });
    } else if (updateList && updateList.message_code === "REPORTEES_EXISTS_ERROR") {
      setUserReporteesState({
        value: true,
        response: updateList
      })
    } else if (updateList && updateList.status === "ERROR") {
      setPage("edit");
      if (
        updateList.message_code &&
        (updateList.message_code === "ACCESS_TOKEN_EXPIRED" ||
          updateList.message_code === "ACCESS_TOKEN_INVALID")
      ) {
      } else if (
        updateList.message_code &&
        updateList.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(updateList && updateList && updateList.message);
      }
    }
  }, [updateList]);

  React.useEffect(() => {
    if (updateReportingManagerResponse && updateReportingManagerResponse.status === "SUCCESS") {
      // setData("");
      setLottie(true);
      // handleClose();
      // dispatch(getProfile());
      // dispatch(getUsers(false));
      setError("");
      // setPage("edit");
      setUserStatusModalType({ value: false });
      setUserReporteesState({
        value: false
      })
      setTimeout(() => setLottie(false), 2000);
    } else if (updateReportingManagerResponse && updateReportingManagerResponse.message_code === "REPORTEES_EXISTS_ERROR") {
      setUserReporteesState({
        value: true,
        response: updateReportingManagerResponse
      })
    } else if (updateReportingManagerResponse && updateReportingManagerResponse.status === "ERROR") {
      setPage("edit");
      if (
        updateReportingManagerResponse.message_code &&
        (updateReportingManagerResponse.message_code === "ACCESS_TOKEN_EXPIRED" ||
          updateReportingManagerResponse.message_code === "ACCESS_TOKEN_INVALID")
      ) {
      } else if (
        updateReportingManagerResponse.message_code &&
        updateReportingManagerResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(updateReportingManagerResponse && updateReportingManagerResponse.message);
      }
    }
  }, [updateReportingManagerResponse]);

  React.useEffect(() => {
    if (assignFacilitiesToZoneResponse && assignFacilitiesToZoneResponse.status === "SUCCESS") {
      setLottie(true);
      setError("");
      setUserStatusModalType({ value: false });
      setFacilityZonesState({
        value: false
      })
      setTimeout(() => setLottie(false), 2000);
    } else if (assignFacilitiesToZoneResponse && assignFacilitiesToZoneResponse.message_code === "FACILITIES_EXISTS_ERROR") {
      setFacilityZonesState({
        value: true,
        response: assignFacilitiesToZoneResponse
      })
    } else if (assignFacilitiesToZoneResponse && assignFacilitiesToZoneResponse.status === "ERROR") {
      toggleZoneModal(true, { data: zoneData ? (zoneData.data || {}) : {} }, "edit");
      if (
        assignFacilitiesToZoneResponse.message_code &&
        (assignFacilitiesToZoneResponse.message_code === "ACCESS_TOKEN_EXPIRED" ||
          assignFacilitiesToZoneResponse.message_code === "ACCESS_TOKEN_INVALID")
      ) {
      } else if (
        assignFacilitiesToZoneResponse.message_code &&
        assignFacilitiesToZoneResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(assignFacilitiesToZoneResponse && assignFacilitiesToZoneResponse.message);
      }
    }
  }, [assignFacilitiesToZoneResponse]);

  React.useEffect(() => {
    if (updateFList && updateFList.status === "SUCCESS") {
      editFacilityModal(false, {}, "create");
      setLottie(true);
      dispatch(getFacilities(facilityInactive));
      setError("");
      setStatusModalType({ value: false });
      setTimeout(() => setLottie(false), 2000);
    } else if (updateFList && updateFList.status === "ERROR") {
      editFacilityModal(true, { data: facilityData ? (facilityData.data || {}) : {} }, "edit");
      if (
        updateFList.message_code &&
        updateFList.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateFList.message_code &&
        updateFList.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else if (updateFList && updateFList.message_code === "EVENT_FACILITY_DELETE_WARN") {
        setStatusModalType({ value: true, status: "confirmation", type: "updateFacility", title: 'Are you sure you want to continue?',
           subtitle: typeof updateFList.message === "string" ?
           <ul>{updateFList.message.split("\n").map(message => {
             return (
               <li>{message}</li>
             )
           })}</ul>
           : <p></p> });
      } else {
        setError(updateFList && updateFList && updateFList.message);
      }
    }
  }, [updateFList]);

  React.useEffect(() => {
    if (addFacility && addFacility.status === "SUCCESS") {
      editFacilityModal(false, {}, "create");
      setLottie(true);
      dispatch(getFacilities(facilityInactive));
      setError("");
      setTimeout(() => setLottie(false), 2000);
    } else if (addFacility && addFacility.status === "ERROR") {
      if (
        addFacility.message_code &&
        addFacility.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (addFacility.message_code === "SESSION_EXPIRED_LOGIN_AGAIN") {
      } else {
        setError(addFacility && addFacility.message);
      }
    }
  }, [addFacility]);

  React.useEffect(() => {
    if (updateHospitalListResponse && updateHospitalListResponse.status === "SUCCESS") {
      toggleHospitalModal(false, {}, "view");
      setLottie(true);
      dispatch(getHospitals(hospitalInactive));
      setError("");
      setTimeout(() => setLottie(false), 2000);
    } else if (updateHospitalListResponse && updateHospitalListResponse.status === "ERROR") {
      toggleHospitalModal(true, { data: hospitalData ? (hospitalData.data || {}) : {} }, "edit");
      if (
        updateHospitalListResponse.message_code &&
        updateHospitalListResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateHospitalListResponse.message_code &&
        updateHospitalListResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(updateHospitalListResponse && updateHospitalListResponse && updateHospitalListResponse.message);
      }
    }
  }, [updateHospitalListResponse]);

  React.useEffect(() => {
    if (addHospitalResponse && addHospitalResponse.status === "SUCCESS") {
      toggleHospitalModal(false, {}, "view");
      setLottie(true);
      dispatch(getHospitals(hospitalInactive));
      setError("");
      setTimeout(() => setLottie(false), 2000);
    } else if (addHospitalResponse && addHospitalResponse.status === "ERROR") {
      if (
        addHospitalResponse.message_code &&
        addHospitalResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (addHospitalResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN") {
      } else {
        setError(addHospitalResponse && addHospitalResponse.message);
      }
    }
  }, [addHospitalResponse]);

  React.useEffect(() => {
    if (updateLabListResponse && updateLabListResponse.status === "SUCCESS") {
      toggleLabModal(false, {}, "view");
      setLottie(true);
      dispatch(getLabs(labInactive));
      setError("");
      setTimeout(() => setLottie(false), 2000);
    } else if (updateLabListResponse && updateLabListResponse.status === "ERROR") {
      toggleLabModal(true, { data: labData ? (labData.data || {}) : {} }, "edit");
      if (
        updateLabListResponse.message_code &&
        updateLabListResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateLabListResponse.message_code &&
        updateLabListResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(updateLabListResponse && updateLabListResponse && updateLabListResponse.message);
      }
    }
  }, [updateLabListResponse]);

  React.useEffect(() => {
    if (addLabResponse && addLabResponse.status === "SUCCESS") {
      toggleLabModal(false, {}, "view");
      setLottie(true);
      dispatch(getLabs(labInactive));
      setError("");
      setTimeout(() => setLottie(false), 2000);
    } else if (addLabResponse && addLabResponse.status === "ERROR") {
      if (
        addLabResponse.message_code &&
        addLabResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (addLabResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN") {
      } else {
        setError(addLabResponse && addLabResponse.message);
      }
    }
  }, [addLabResponse]);

  React.useEffect(() => {
    if (updateTerritoryListResponse && updateTerritoryListResponse.status === "SUCCESS") {
      toggleTerritoryModal(false, {}, "view");
      setLottie(true);
      dispatch(getTerritories());
      setError("");
      setTimeout(() => setLottie(false), 2000);
    } else if (updateTerritoryListResponse && updateTerritoryListResponse.status === "ERROR") {
      toggleTerritoryModal(true, { data: territoryData ? (territoryData.data || {}) : {} }, "edit");
      if (
        updateTerritoryListResponse.message_code &&
        updateTerritoryListResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateTerritoryListResponse.message_code &&
        updateTerritoryListResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(updateTerritoryListResponse && updateTerritoryListResponse && updateTerritoryListResponse.message);
      }
    }
  }, [updateTerritoryListResponse]);

  React.useEffect(() => {
    if (addTerritoryResponse && addTerritoryResponse.status === "SUCCESS") {
      toggleTerritoryModal(false, {}, "view");
      setLottie(true);
      dispatch(getTerritories());
      setError("");
      setTimeout(() => setLottie(false), 2000);
    } else if (addTerritoryResponse && addTerritoryResponse.status === "ERROR") {
      if (
        addTerritoryResponse.message_code &&
        addTerritoryResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (addTerritoryResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN") {
      } else {
        setError(addTerritoryResponse && addTerritoryResponse.message);
      }
    }
  }, [addTerritoryResponse]);

  React.useEffect(() => {
    if (updateZoneListResponse && updateZoneListResponse.status === "SUCCESS") {
      toggleZoneModal(false, {}, "view");
      setLottie(true);
      dispatch(getZones(zoneInactive));
      setError("");
      setFacilityZonesState({ value: false });
      setStatusModalTypeZone({ value: false });
      setTimeout(() => setLottie(false), 2000);
    } else if (updateZoneListResponse && updateZoneListResponse.status === "ERROR") {
      toggleZoneModal(true, { data: zoneData ? (zoneData.data || {}) : {} }, "edit");
      if (
        updateZoneListResponse.message_code &&
        updateZoneListResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateZoneListResponse.message_code &&
        updateZoneListResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else if (updateZoneListResponse && updateZoneListResponse.message_code === "FACILITIES_EXISTS_ERROR") {
        setFacilityZonesState({
          value: true,
          response: updateZoneListResponse
        })
      } else if (updateZoneListResponse && updateZoneListResponse.message_code === "EVENT_ZONE_DELETE_WARN") {
        setStatusModalTypeZone({ value: true, status: "confirmation", type: "updateFacility", content: updateZoneListResponse.message });
      } else {
        setError(updateZoneListResponse && updateZoneListResponse && updateZoneListResponse.message);
      }
    }
  }, [updateZoneListResponse]);

  React.useEffect(() => {
    if (addZoneResponse && addZoneResponse.status === "SUCCESS") {
      toggleZoneModal(false, {}, "view");
      setLottie(true);
      dispatch(getZones(zoneInactive));
      setError("");
      setTimeout(() => setLottie(false), 2000);
    } else if (addZoneResponse && addZoneResponse.status === "ERROR") {
      if (
        addZoneResponse.message_code &&
        addZoneResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (addZoneResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN") {
      } else {
        setError(addZoneResponse && addZoneResponse.message);
      }
    }
  }, [addZoneResponse]);

  React.useEffect(() => {
    if (facilities && paramId) {
      if (tabValue === "manageCare" && facilities.status === "SUCCESS") {
        setFacilityData({ isOpen: true, mode: "view", data: {} });
        dispatch(getFacility(paramId))
      }
    }
  }, [facilities])

  React.useEffect(() => {
    if (users && paramId) {
      if (tabValue === "manageUsers" && users.status === "SUCCESS") {
        setData({});
        dispatch(getUser(paramId));
        setPage("profile");
        setShow(true);
      }
    }
  }, [users])

  React.useEffect(() => {
    if (hospitals && paramId) {
      if (tabValue === "manageHospitals" && hospitals.status === "SUCCESS") {
        dispatch(getHospital(paramId))
      }
    }
  }, [hospitals])

  React.useEffect(() => {
    if (labs && paramId) {
      if (tabValue === "manageLabs" && labs.status === "SUCCESS") {
        dispatch(getLab(paramId));
      }
    }
  }, [labs])

  React.useEffect(
    () => () => {
      //clear redux state
      dispatch(clearAddUserResponse());
      dispatch(clearUpdateUserResponse());
      dispatch(clearAddFacilityResponse());
      dispatch(clearUpdateFacilityResponse());
      dispatch(clearAddHospitalResponse());
      dispatch(clearUpdateHospitalResponse());
      dispatch(clearAddLabResponse());
      dispatch(clearAddTerritoryResponse());
      dispatch(clearUpdateTerritoryResponse());
      dispatch(clearAddZoneResponse());
      dispatch(clearUpdateZoneResponse());
      dispatch(clearUpdateLabResponse());
      dispatch(clearUpdateFacilityToZoneVal());
      dispatch(setUserList());
      dispatch(setFacilityList());
      dispatch(setHospitalList());
      dispatch(setLabList());
      dispatch(setTerritoryList());
      dispatch(setZoneList());
      document.documentElement.classList.remove(adminClass);
    },
    []
  );

  const handleAssignReportingManager = (userId) => {
    const { selectedManager = {}, selectedReportees = {} } = userReporteesState;
    let ids = [];
    Object.keys(selectedReportees).forEach(id => {
      if (selectedReportees[id]) {
        ids.push(parseInt(id));
      }
    })
    dispatch(assignReportingManager({
      deactivating_user_id: userId,
      user_ids: ids,
      reporting_manager_id: selectedManager.id
    }))
  }

  const handleAssignZoneToFacilities = (zoneId) => {
    const { selectedZone = {}, selectedFacilities = {} } = facilityZonesState;
    let ids = [];
    Object.keys(selectedFacilities).forEach(id => {
      if (selectedFacilities[id]) {
        ids.push(parseInt(id));
      }
    })
    dispatch(assignFacilitiesToZone({
      deactivating_zone_id: zoneId,
      facility_ids: ids,
      zone_id: selectedZone.zoneId
    }))
  }

  const handleClose = () => {
    setError("");
    setShow();
  };

  const handleDeactivate = (flag) => {
    setInactive(flag);
    dispatch(getUsers(flag));
  };

  const handleChangeDeactivate = (type, flag = false) => {
    if (type === "facility") {
      setFacilityInactive(flag);
      dispatch(getFacilities(flag));
    } else if (type === "lab") {
      setLabInactive(flag);
      dispatch(getLabs(flag));
    } else if (type === "hospital") {
      setHospitalInactive(flag);
      dispatch(getHospitals(flag));
    } else if (type === "territory") {
      dispatch(getTerritories());
    } else if (type === "zone") {
      setZoneInactive(flag);
      dispatch(getZones(flag));
    }
  };

  const handleUpdateFacility = (data) => {
    dispatch(updateFacility(data));
  };

  const handleCreateFacility = (data) => {
    dispatch(addFacilities(data));
  };

  const handleUpdateHospital = (data) => {
    dispatch(updateHospital(data));
  };

  const handleCreateHospital = (data) => {
    dispatch(addHospitals(data));
  };

  const handleUpdateLab = (data) => {
    dispatch(updateLab(data));
  };

  const handleCreateLab = (data) => {
    dispatch(addLabs(data));
  };

  const handleUpdateTerritory = (data) => {
    dispatch(updateTerritory(data));
  };

  const handleCreateTerritory = (data) => {
    dispatch(addTerritories(data));
  };

  const handleUpdateZone = (data) => {
    dispatch(updateZone(data));
  };

  const handleCreateZone = (data) => {
    dispatch(addZones(data));
  };

  const handleEdit = (data) => {
    dispatch(updateUser(data));
  };

  const handleError = () => {
    dispatch(clearUpdateUserResponse());
    dispatch(clearUpdateReportingManagerVal());
    dispatch(clearUpdateFacilityToZoneVal());
    setError("");
    setPage("profile");
  };
  const editModal = (data) => {
    setData(data && data.data);
    if (data && data.data && data.data.id) {
      dispatch(getUser(data.data.id));
    }
    setPage("profile");
    setShow(true)
  };

  const editFacilityModal = (value, data = {}, mode = "create") => {
    setFacilityData({ isOpen: value, mode, data: data.data || {} });
    if (value && mode === "view" && data) {
      data.data && dispatch(getFacility(data.data.id));
    }
  };

  const toggleHospitalModal = (value, data = {}, mode = "view") => {
    setHospitalData({ isOpen: value, mode, data: data.data || {} })
    if (value && mode === "view" && data) {
      data.data && dispatch(getHospital(data.data.id));
    }
  };

  const toggleLabModal = (value, data = {}, mode = "view") => {
    setLabData({ isOpen: value, mode, data: data.data || {} })
    if (value && mode === "view" && data) {
      data.data && dispatch(getLab(data.data.id));
    }
  };

  const toggleTerritoryModal = (value, data = {}, mode = "view") => {
    setTerritoryData({ isOpen: value, mode, data: data.data || {} })
    if (value && mode === "view" && data) {
      data.data && dispatch(getTerritory(data.data.territory_id));
    }
  };

  const toggleZoneModal = (value, data = {}, mode = "view") => {
    setZoneData({ isOpen: value, mode, data: data.data || {} })
    if (value && mode === "view" && data) {
      data.data && dispatch(getZone(data.data.zone_id));
    }
  };

  const handleFacilityError = (mode) => {
    setError("");
    setFacilityData({ ...facilityData, mode });
  };

  const handleHospitalError = () => {
    setError("");
    setHospitalData({ ...hospitalData, mode: "view" });
  };

  const handleLabError = () => {
    setError("");
    setLabData({ ...labData, mode: "view" });
  };

  const handleTerritoryError = () => {
    setError("");
    setTerritoryData({ ...territoryData, mode: "view" });
  };

  const handleZoneError = () => {
    setError("");
    setZoneData({ ...zoneData, mode: "view" });
  };

  const filterTerr = (data) => {
    // console.log(data);
  }

  const loadAllRegions = async () => {
    const response = await ahaApi.Account.fetchRegions()
      .then((res) => {
        console.log(res, 'resres');
        let result = res.content || [];
        return result;
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  };
  const loadAllTerritories = async () => {
    const response = await ahaApi.Account.fetchTerritories()
      .then((res) => {
        console.log(res, 'resres');
        let result = res.content || [];
        return result;
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  };
  return (
    <React.Fragment>
      <InnerHeader
        inactive_user={inactive_user}
        setInactive={handleDeactivate}
        userList={data}
        loading={loading}
        editModal={editModal}
        toggleFacilityModal={editFacilityModal}
        toggleHospitalModal={toggleHospitalModal}
        toggleLabModal={toggleLabModal}
        toggleTerritoryModal={toggleTerritoryModal}
        toggleZoneModal={toggleZoneModal}
        filterTerr={filterTerr}
        bc={bc}
        job_title={job_title}
        region={region}
        roles={roles}
        territory={territory}
        full_name={full_name}
        color={color}
        profileLoading={profileLoading}
        profileData={profileData}
        facilityList={facilityList}
        hospitalList={hospitalList}
        labList={labList}
        territoryList={territoryList}
        zoneList={zoneList}
        faciLoading={faciLoading}
        hospitalLoader={hospitalLoader}
        labLoader={labLoader}
        territoryLoader={territoryLoader}
        zoneLoader={zoneLoader}
        states={states}
        facility_type={facility_type}
        days={days}
        facilityServiceType={facilityServiceType}
        handleChangeDeactivate={handleChangeDeactivate}
        facilityInactive={facilityInactive}
        labInactive={labInactive}
        hospitalInactive={hospitalInactive}
        zoneInactive={zoneInactive}
        tabRef={tabRef}
        tabValue={tabValue}
      />
      <Profile
        job={job_title}
        roles={roles}
        region={region}
        territory={territory}
        userList={data}
        handleClose={handleClose}
        show={show}
        editData={editData}
        handleEdit={handleEdit}
        error={error}
        page={page}
        handleError={handleError}
        bc={bc}
        statusModalType={userStatusModalType}
        setStatusModalType={setUserStatusModalType}
        setPage={setPage}
        handleAssignReportingManager={handleAssignReportingManager}
        userReporteesState={userReporteesState}
        setUserReporteesState={setUserReporteesState}
        userObjLoading={userObjLoading}
        paramId={paramId}
        history={history}
      />
      <FacilityAddEdit
        facilityModal={facilityData.isOpen || false}
        handleClose={() => {
          editFacilityModal(false)
        }}
        editFacilityModal={editFacilityModal}
        facilityData={facilityData.data || {}}
        loading={faciLoading}
        timeZones={timezone_ids}
        states={states}
        zone={zone}
        facilityTypes={facility_type}
        facilityServiceType={facilityServiceType}
        days={days}
        bc={bc}
        mode={facilityData.mode || "create"}
        handleFacilityError={handleFacilityError}
        handleUpdateFacility={handleUpdateFacility}
        error={error}
        territory={territory}
        serviceStopDays={serviceStopDays}
        updateFList={updateFList}
        addFacility={addFacility}
        handleCreateFacility={handleCreateFacility}
        editFacility={editFacility}
        statusModalType={statusModalType}
        setStatusModalType={setStatusModalType}
        facilityObjLoading={facilityObjLoading}
        paramId={paramId}
        history={history}
      />
      <HospitalAddEdit
        isOpen={hospitalData.isOpen || false}
        handleClose={() => toggleHospitalModal(false)}
        toggleHospitalModal={toggleHospitalModal}
        hospitalData={hospitalData.data || {}}
        loading={hospitalLoader}
        timeZones={hospital_timezone_ids}
        mode={hospitalData.mode || "view"}
        handleHospitalError={handleHospitalError}
        handleUpdateHospital={handleUpdateHospital}
        error={error}
        updateHospitalList={updateHospitalListResponse}
        addHospitalList={addHospitalResponse}
        handleCreateHospital={handleCreateHospital}
        hospitalObjLoading={hospitalObjLoading}
        paramId={paramId}
        history={history}
        hospital_type={hospital_type}
        partners={partners}
      />
      <LabAddEdit
        isOpen={labData.isOpen || false}
        handleClose={() => toggleLabModal(false)}
        toggleLabModal={toggleLabModal}
        labData={labData.data || {}}
        loading={labLoader}
        timeZones={timezone_ids}
        states={states}
        days={days}
        mode={labData.mode || "view"}
        handleLabError={handleLabError}
        handleUpdateLab={handleUpdateLab}
        error={error}
        updateLabList={updateLabListResponse}
        addLabList={addLabResponse}
        handleCreateLab={handleCreateLab}
        labTypes={labTypesData}
        labObjLoading={labObjLoading}
        paramId={paramId}
        history={history}
      />
      {territoryData.isOpen && <TerritoryAddEdit
        isOpen={territoryData.isOpen || false}
        handleClose={() => toggleTerritoryModal(false)}
        toggleTerritoryModal={toggleTerritoryModal}
        territoryData={territoryData.data || {}}
        loading={territoryLoader}
        mode={territoryData.mode || "view"}
        handleTerritoryError={handleTerritoryError}
        handleUpdateTerritory={handleUpdateTerritory}
        error={error}
        updateTerritoryList={updateTerritoryListResponse}
        addTerritoryList={addTerritoryResponse}
        handleCreateTerritory={handleCreateTerritory}
        loadAllRegions={loadAllRegions}
        territoryObjLoading={territoryObjLoading}
      />}
      {zoneData.isOpen && <ZoneAddEdit
        isOpen={zoneData.isOpen || false}
        handleClose={() => toggleZoneModal(false)}
        toggleZoneModal={toggleZoneModal}
        zoneData={zoneData.data || {}}
        loading={zoneLoader}
        mode={zoneData.mode || "view"}
        handleZoneError={handleZoneError}
        handleUpdateZone={handleUpdateZone}
        error={error}
        updateZoneList={updateZoneListResponse}
        addZoneList={addZoneResponse}
        handleCreateZone={handleCreateZone}
        loadAllTerritories={loadAllTerritories}
        zoneObjLoading={zoneObjLoading}
        facilityZonesState={facilityZonesState}
        setFacilityZonesState={setFacilityZonesState}
        handleAssignZoneToFacilities={handleAssignZoneToFacilities}
        statusModalType={statusModalTypeZone}
        setStatusModalType={setStatusModalTypeZone}
        timezone_ids={timezone_ids}
      />}
      {lottie && <LottieAnimation />}
    </React.Fragment>
  );
}
