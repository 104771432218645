import { HOMEHEALTH_CLEAR_DATA, HOMEHEALTH_GRID_FILTERING, HOMEHEALTH_GRID_LOADING, HOMEHEALTH_GRID_PAGING, HOMEHEALTH_GRID_SORTING, TOGGLE_HIDE_FILTER } from "../../actions/action";
import ahaApi from "../../app/services/aha-api";
import moment from "moment";
import _ from "lodash";
import { ACKNOWLEDGED_STATUS, CANCEL_TEXT, CANCELLED_STATUS, findLastIndex, NATERA_ID, NATERA_REQUESTED_TEXT, replaceNullWithEmptyStringAndReturnUpdated, UNACKNOWLEDGED_STATUS } from "../../app/utils/Constants";
import { phoneFormat } from "../../app/utils/testUtils";

const initialState = {
  data: [],
  error: false,
  result: [],
  count: 0,
  allGridData: [],
  loading: false,
  hideFilter: false
}

export const getHomeHealth = (params, resetAllGridData) => async (dispatch) => {
  dispatch({ type: HOMEHEALTH_GRID_LOADING, payload: true });
  try {
    const res = await ahaApi.Account.getHomeHealthOrders(params);
    let { content = {} } = res;
    let { orders = [], count } = content;

    // data to show in listing
    const listingOrders = orders.map(c => {
      const updatedObject = replaceNullWithEmptyStringAndReturnUpdated(c);
      const obj = constructListingData(updatedObject);
      return obj;
    })

    //  data to populate modal fields
    const modalDataList = orders.map(c => {
      //  data to populate modal fields
      const obj = constructListingData(c);
      return obj;
    })
    res.content = { result: listingOrders, modalDataList, count };
    dispatch({
      type: HOMEHEALTH_GRID_PAGING,
      payload: res.content || [],
      tCount: res.content.count,
      resetAllGridData
    })
  } catch (error) {
    dispatch({ type: HOMEHEALTH_GRID_LOADING, payload: false });
  }
  dispatch({ type: HOMEHEALTH_GRID_LOADING, payload: false });
}
export const setHideFilter = (value) => ({
  type: TOGGLE_HIDE_FILTER,
  payload: value,
});

export const setHomeHealthList = (value) => ({
  type: HOMEHEALTH_CLEAR_DATA,
  payload: value,
});

export const homeHealthReducer = (state = initialState, action, gquery) => {

  switch (action.type) {
    case HOMEHEALTH_GRID_PAGING: {
      const {resetAllGridData = true} = action;
      const newRecords = action.payload?.result;

      let existingGridData = [];

      if (resetAllGridData) {
        existingGridData = newRecords;
      } else {
        existingGridData = state.allGridData || [];
        const existingIds = new Set(existingGridData.map((record) => record.id));
        // Filter new records to avoid duplicates
        const filteredNewRecords = newRecords.filter(
          (record) => !existingIds.has(record.id)
        );
        existingGridData = [...existingGridData, ...filteredNewRecords];
      }

      return ({
        data: { result: newRecords, modalDataList: action.payload?.modalDataList, count: action.tCount }, allGridData: existingGridData
      })
    }
    case HOMEHEALTH_GRID_FILTERING: {
      return ({
        data: { result: action.payload, count: action.tCount }
      })
    }
    case HOMEHEALTH_GRID_SORTING: {
      return ({
        data: { result: action.payload, count: action.tCount }
      })
    }
    case HOMEHEALTH_GRID_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case HOMEHEALTH_CLEAR_DATA: {
      return {
        ...state,
        data: [],
        allGridData: []
      };
    }
    default: {
      return state;
    }
  }
}

const constructListingData = (data) => {
    const { id, patient_first_name = "", patient_last_name = "", notes = [], order_creation_datetime = "", user = {}, coordinator = {},
      partner = {}, schedule_confirmation = {}, patient_dob, gender = {}, partner_order_id = "",
      patient_address1, patient_address2, patient_city, patient_state, patient_zip, region = {}, phleb_activity_informations = [], drop_off_points_location = [], drop_off_points = [], patient_phone, patient_notes = '',
      order_status = {}, ordering_physician = "", order_service = {}, patient_email = "", address_type = {}, sms_opt_in = "",
      order_accessions = [], partner_order_attribute = {}, result_finalized_date_time = "", notified_person = "", order_line_items = [], last_updated_datetime, order_creation_timezone = {},
      last_updated_timezone = {}, result_finalized_timezone = {}, is_validated_by_mapbox, phleb_activities = {}, order_creation_method = {}, created_by = {} } = data;
  
    let tempNotes = notes ? notes.length ? notes.sort((a, b) => new Date(a.created_date_time) > new Date(b.created_date_time)
      ? -1
      : new Date(a.created_date_time) < new Date(b.created_date_time)
        ? 1
        : 0) : [] : []
    const cancellationNotes = tempNotes.filter(note => note.note_type && note.note_type.note_type_name == CANCEL_TEXT || note.note_type.note_type_name == NATERA_REQUESTED_TEXT);
    const notesWithoutCancellation = tempNotes.filter(note => note.note_type && note.note_type.note_type_name != CANCEL_TEXT && note.note_type.note_type_name != NATERA_REQUESTED_TEXT && note.notes);
  
    let nateraCancellationNotes = "";
    if (order_status && order_status.id == CANCELLED_STATUS) {
      if (cancellationNotes && cancellationNotes.length === 1) {
        nateraCancellationNotes = cancellationNotes[0].note_type.note_type_label;
      }
    }
    let tempCheckIn = {};
    let tempCheckOut = {};
    let tempDropOffString = "";
    let tempDropOffs = [];
  
    let checkIns =
      phleb_activity_informations.filter((act) => act.action_type_id === 1) ||
      [];
    let checkOuts =
      phleb_activity_informations.filter((act) => act.action_type_id === 2) ||
      [];
    let dropOffs =
      phleb_activity_informations.filter((act) => act.action_type_id === 3) ||
      [];
    let checkInObj = {};
    let checkOutObj = {};
  
    if (dropOffs.length) {
  
      let checkinIndex = findLastIndex(
        phleb_activity_informations || [],
        (act) => act.action_type_id == 1
      );
  
      checkInObj = phleb_activity_informations ? phleb_activity_informations[checkinIndex] || {} : {};
  
      let checkoutIndex = findLastIndex(
        phleb_activity_informations,
        (act) => act.action_type_id == 2
      );
  
      checkOutObj = phleb_activity_informations[checkoutIndex] || {};
  
    } else {
      if (checkIns.length > checkOuts.length) {
        checkInObj = phleb_activity_informations.length
          ? phleb_activity_informations[phleb_activity_informations.length - 1]
            ? phleb_activity_informations[
              phleb_activity_informations.length - 1
            ].action_type_id === 1
              ? phleb_activity_informations[
              phleb_activity_informations.length - 1
              ]
              : phleb_activity_informations[
                phleb_activity_informations.length - 2
              ]
                ? phleb_activity_informations[
                  phleb_activity_informations.length - 2
                ].action_type_id === 1
                  ? phleb_activity_informations[
                  phleb_activity_informations.length - 2
                  ]
                  : {}
                : {}
            : {}
          : {};
        checkOutObj = {};
      } else {
        let checkinIndex = findLastIndex(
          phleb_activity_informations || [],
          (act) => act.action_type_id == 1
        );
  
        checkInObj = phleb_activity_informations ? phleb_activity_informations[checkinIndex] || {} : {};
  
        let checkoutIndex = findLastIndex(
          phleb_activity_informations,
          (act) => act.action_type_id == 2
        );
  
        checkOutObj = phleb_activity_informations[checkoutIndex] || {};
      }
    }
  
    if (checkInObj && Object.keys(checkInObj).length) {
      tempCheckIn = {
        ...checkInObj,
        actionDateTime: checkInObj.action_datetime
          ? new Date(checkInObj.action_datetime)
          : ""
      };
    }
  
    if (checkOutObj && Object.keys(checkOutObj).length) {
      tempCheckOut = {
        ...checkOutObj,
        actionDateTime: checkOutObj.action_datetime
          ? new Date(checkOutObj.action_datetime)
          : ""
      };
    }
  
    const drop_offs = phleb_activities["Drop Off"] || [];
  
    drop_offs && drop_offs.forEach(dd => {
      const { drop_off_types = [], action_id } = dd
      if (action_id) {
        let selectedDropOffOption;
        drop_off_types.forEach(dt => {
          if (dt.selected) {
            selectedDropOffOption = dt;
          }
        });
        if (selectedDropOffOption) {
          tempDropOffs.push(dd);
        }
      }
    });
    if (tempDropOffs && tempDropOffs.length) {
      tempDropOffString = tempDropOffs.map(obj => {
        return obj.action_datetime ? `${moment(new Date(obj.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${obj.timezone ? obj.timezone.abbreviation || "" : ""}` : ""
      }).join(", ")
  
    }
  
    let checkInDateTime =
      order_status &&
        (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) &&
        phleb_activity_informations.length > 0
        ? {}
        : tempCheckIn;
    let checkOutDateTime =
      order_status &&
        (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) &&
        phleb_activity_informations.length > 0
        ? {}
        : tempCheckOut;
  
    let dropOffDateTime =
      order_status &&
        (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) &&
        phleb_activity_informations.length > 0
        ? ""
        : tempDropOffString;
  
    const checkoutStatus = tempCheckOut ? tempCheckOut.checkout_status ? tempCheckOut.checkout_status.label || "-" : "-" : "-"
  
    let accessionInfo = ""
    if (order_accessions && order_accessions.length) {
      accessionInfo = order_accessions.map(acc => { return acc.accession_datetime ? `(${acc.accession_id} - ${moment(new Date(acc.accession_datetime)).format("MM/DD/yyyy HH:mm:ss")})` : acc.accession_id }).join(", ")
    }
    let accName = "";
    let accNumber = "";
    if (partner_order_attribute) {
      accNumber = partner_order_attribute[`${partner.partner_name} Account Number`] ? partner_order_attribute[`${partner.partner_name} Account Number`].label : "";
      accName = partner_order_attribute[`${partner.partner_name} Account Name`] ? partner_order_attribute[`${partner.partner_name} Account Name`].label : ""
    }
  
    let visitTypes = order_line_items ? order_line_items.length ? order_line_items.filter(item => item.order_collection_method && item.order_collection_method.collection_type) : [] : [];
    visitTypes = visitTypes ? visitTypes.length ? visitTypes.map(item => item.order_collection_method && item.order_collection_method.collection_type).join("; ") : "-" : "-"

    let TestCodes = [];
    let TestDescriptions = [];
    let AllomapDrawSite = [];

    if (order_line_items) {
      order_line_items.forEach((order) => {
        if (order.test_code_name || (order.test_code_id && order.test_code_id.test_code_name)) {
          TestDescriptions.push(order.test_code_name || (order.test_code_id ? order.test_code_id.test_code_name : ""));
        }

        if (order.test_code || order.test_code_name || (order.test_code_id && order.test_code_id.test_code)) {
          TestCodes.push(order.test_code || order.test_code_name || (order.test_code_id ? order.test_code_id.test_code : ""));
        }

        if (order.draw_site && order.draw_site.drawSiteName) {
          AllomapDrawSite.push(order.draw_site.drawSiteName);
        }
      });
    }
  
    return {
      id,
      status_color_code: data.status_color_code,
      OrderStatus: order_status.order_status_name,
      OrderNumber: data.order_number,
      OrderCreated: `${moment(new Date(order_creation_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${order_creation_timezone ? order_creation_timezone.abbreviation || "" : ""}`,
      PartnerOrderID: !_.isEmpty(partner_order_id) ? partner_order_id : "-",
      OrderCreationMethod: order_creation_method ? order_creation_method.method_name || "-" : "-",
      OrderCreationUser: created_by ? created_by.name || "-" : "-",
      CheckoutStatus: checkoutStatus,
      ScheduledDate: schedule_confirmation.schedule_date ? `${moment(new Date(`${schedule_confirmation.schedule_date} 00:00:00`)).format("MM/DD/yyyy")} ${schedule_confirmation.scheduled_timezone ? schedule_confirmation.scheduled_timezone.abbreviation || "" : ""}` : "-",
      ScheduledStartTime: schedule_confirmation.start_date_time ? `${moment(new Date(schedule_confirmation.start_date_time)).format("HH:mm:ss")} ${schedule_confirmation.scheduled_timezone ? schedule_confirmation.scheduled_timezone.abbreviation || "" : ""}` : "-",
      ScheduledEndTime: schedule_confirmation.end_date_time ? `${moment(new Date(schedule_confirmation.end_date_time)).format("HH:mm:ss")} ${schedule_confirmation.scheduled_timezone ? schedule_confirmation.scheduled_timezone.abbreviation || "" : ""}` : "-",
      Region: !_.isEmpty(region) ? region.region_name || "-" : "-",
      Coordinator: !_.isEmpty(coordinator) ? coordinator.name ? coordinator.name : "-" : "-",
      CoordinatorEmpID: !_.isEmpty(coordinator) ? coordinator.employee_id ? coordinator.employee_id : "-" : "-",
      Phlebotomist: !_.isEmpty(user) ? user.name ? user.name : "-" : "-",
      PhlebEmpID: !_.isEmpty(user) ? user.employee_id ? user.employee_id : "-" : "-",
      PatientName: !_.isEmpty(patient_first_name) && !_.isEmpty(patient_last_name) ? `${patient_first_name} ${patient_last_name}` : '-',
      PatientPhoneNumber: patient_phone ? !_.isEmpty(patient_phone.toString()) ? phoneFormat(patient_phone.toString()) : "-" : "-",
      PatientRequestedDateTime: !_.isEmpty(patient_notes) ? patient_notes : "-",
      PatientDOB: !_.isEmpty(patient_dob) ? moment(new Date(`${patient_dob} 00:00:00`)).format("MM/DD/yyyy") : "-",
      PatientBiologicalSex: !_.isEmpty(gender) ? gender.type || "-" : "-",
      PatientStreetAddress: !_.isEmpty(patient_address1) ? `${patient_address1} ${!_.isEmpty(patient_address2) ? `, ${patient_address2}` : ""}` : "-",
      City: !_.isEmpty(patient_city) ? patient_city : "-",
      State: !_.isEmpty(patient_state) ? patient_state.state_name || "-" : "-",
      Zip: !_.isEmpty(patient_zip) ? patient_zip : "-",
      DropOffType: !_.isEmpty(drop_off_points) ? drop_off_points.length > 0 ? drop_off_points.join(", ") : "-" : "-",
      DropLocationName: !_.isEmpty(drop_off_points_location) ? drop_off_points_location.length > 0 ? drop_off_points_location.join(", ") : "-" : "-",
      PartnerName: !_.isEmpty(partner) ? partner.partner_name || "-" : "-",
      PartnerID: !_.isEmpty(partner) ? partner.external_partner_id || "-" : "-",
      CancellationReason: cancellationNotes.length ? cancellationNotes.map(note => note.notes).join(", ") : "-",
      Notes: notesWithoutCancellation.length ? notesWithoutCancellation.map(note => note.notes).join(", ") : "-",
      LastUpdatedDateTime: last_updated_datetime ? `${moment(new Date(last_updated_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${last_updated_timezone ? last_updated_timezone.abbreviation || "" : ""}` : "-",
      NateraCancellationInitiator: partner ? partner.id == NATERA_ID ? !_.isEmpty(nateraCancellationNotes) ? nateraCancellationNotes : "-" : "-" : "-",
      OrderingPhysician: !_.isEmpty(ordering_physician) ? ordering_physician : "-",
      ServiceType: !_.isEmpty(order_service) ? order_service.order_service_type_name || "-" : "-",
      PatientEmailAddress: !_.isEmpty(patient_email) ? patient_email : "-",
      PatientAddressType: !_.isEmpty(address_type) ? address_type.address_type_name || "-" : "-",
      SMSOptin: !_.isEmpty(sms_opt_in) ? sms_opt_in : "-",
      PatientConfirmation: schedule_confirmation ? schedule_confirmation.patient_confirmation ? "Yes" : "No" : "No",
      PatienthasPartnerkit: schedule_confirmation ? !_.isEmpty(schedule_confirmation.patient_has_partner_kit) ? schedule_confirmation.patient_has_partner_kit : "-" : "-",
      AccountNumber: accNumber,
      AccountName: accName,
      AccessionInformation: accessionInfo ? accessionInfo : "-",
      NotifiedPerson: !_.isEmpty(notified_person) ? notified_person : "-",
      ResultFinalizedDate: result_finalized_date_time ? `${moment(new Date(result_finalized_date_time)).format("MM/DD/yyyy")} ${result_finalized_timezone ? result_finalized_timezone.abbreviation || "" : ""}` : "-",
      ResultFinalizedTime: result_finalized_date_time ? `${moment(new Date(result_finalized_date_time)).format("HH:mm:ss")} ${result_finalized_timezone ? result_finalized_timezone.abbreviation || "" : ""}` : "-",
      ScheduledTimezone: schedule_confirmation ? !_.isEmpty(schedule_confirmation.scheduled_timezone) ? schedule_confirmation.scheduled_timezone.timezone_name || "-" : "-" : "-",
      TestCode: TestCodes ? TestCodes.length > 0 ? TestCodes.join("; ") : "-" : "-",
      SupervisorName: !_.isEmpty(user) ? user.reporting_manager_name ? user.reporting_manager_name : "-" : "-",
      CheckIn: checkInDateTime ? checkInDateTime.action_datetime ? `${moment(new Date(checkInDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${checkInDateTime.timezone ? checkInDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
      CheckOut: checkOutDateTime ? checkOutDateTime.action_datetime ? `${moment(new Date(checkOutDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${checkOutDateTime.timezone ? checkOutDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
      VisitType: visitTypes,
      PartnerKitRequired: order_line_items ? order_line_items.length ? order_line_items.map(item => item.partner_test_kit_required === undefined || item.partner_test_kit_required === null ? "" : item.partner_test_kit_required ? "Yes" : "No").join("; ") : "-" : "-",
      TestDescription: TestDescriptions ? TestDescriptions.length > 0 ? TestDescriptions.join("; ") : "-" : "-",
      DropOff: dropOffDateTime || "-",
      ValidatedByMapboxString: is_validated_by_mapbox ? "Yes" : "No",
      order_creation_datetime_for_modal: order_creation_datetime,
      AllomapDrawSite: AllomapDrawSite ? AllomapDrawSite.length > 0 ? AllomapDrawSite.join("; ") : "-" : "-",
    }
  }