import {
	ChipDirective,
	ChipListComponent,
	ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import { phoneFormat } from "../../app/utils/testUtils";
import CustomGrid from "../../components/commons/CustomGrid";

export default function HospitalList(props) {
	const { data = [], loading = false, days = [] } = props;

	// const getDayName = (id) => {
	// 	let result = id;
	// 	days && days.forEach(day => {
	// 	  if (day.id == id) {
	// 		result = day.name;
	// 	  }
	// 	})
	// 	return result;
	//   }

	// const valueAccessor = (field, fieldData, column) => {
	// 	const hosTimings = fieldData['hospital_timings'] || [];
	// 	return  hosTimings ? hosTimings.map(day =>{return `( day: ${getDayName(day.day_id)}, startTime: ${day.start_time}, endTime: ${day.end_time} )`}).join(", ") : "";
	// }

	return (
		<CustomGrid
			type="hospital"
			data={data}
			loading={loading}
			editModal={props.editModal}
			columns={[
				{
					field: "hospital_name",
					headerText: "Hospital Name",
					width: "220",
				},
				{
					field: "fullAddress",
					headerText: "Address",
					width: "220"
				},
				{
					field: "city_string",
					headerText: "City",
					width: "220",
				},
				{
					field: "state_name_string",
					headerText: "State",
					width: "220",
				},
				{
					field: "zip_string",
					headerText: "Zip",
					width: "168",
					visible: false,
				},
				{
					field: "zone_name_string",
					headerText: "Zone",
					width: "220",
					visible: false,
				},
				// {
				// 	field: "street_address1",
				// 	headerText: "Street Address 1",
				// 	width: "220",
				// 	visible: false
				//   },
				//   {
				// 	field: "street_address2",
				// 	headerText: "Street Address 2",
				// 	width: "220",
				// 	visible: false
				//   },
				{
					field: "time_zone_name_string",
					headerText: "Timezone",
					width: "168",
					visible: false,
				},
				{
					field: "latitude_string",
					headerText: "Latitude",
					width: "168",
					visible: false,
				},
				{
					field: "longitude_string",
					headerText: "Longitude",
					width: "168",
					visible: false,
				},
				{
					field: "account_number_string",
					headerText: "Account Number",
					width: "220",
				},
				{
					field: "hospital_type_string",
					headerText: "Hospital Type",
					width: "220",
				},
				{
					field: "partner_names_string",
					headerText: "Partner Name(s)",
					width: "220",
				},
				{
					field: "hospital_contact_phone_number_string",
					headerText: "Phone Number",
					width: "250"
				},
				{
					field: "hospital_contact_phone_number_ext_string",
					headerText: "Phone Number Extension",
					width: "220",
					visible: false,
				},
				{
					field: "hospital_notes_string",
					headerText: "Hospital Notes",
					width: "220",
					visible: false,
				},
				// {
				// 	field: "hospital_timings",
				// 	headerText: "Timings",
				// 	width: "220",
				// 	visible: false,
				// 	valueAccessor
				//   },
				{
					field: "status.status_name",
					headerText: "Status",
					width: "168",
					template: (props) => {
						const { status = {} } = props;
						return status && status.status_name === "Active" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-active"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : status && status.status_name === "Invited" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-invited"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : status && status.status_name === "Locked" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-locked"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : (
							status &&
							status.status_name === "Inactive" && (
								<div className="user-status-wrapper">
									<ChipListComponent id="chip-default">
										<ChipsDirective>
											<ChipDirective
												text={status.status_name}
												cssClass="e-user-deactivated"
											></ChipDirective>
										</ChipsDirective>
									</ChipListComponent>
								</div>
							)
						);
					},
				},
			]}
		/>
	);
}
