import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getHospitalFilter = createAsyncThunk("hospital/getHospitalFilter", async () => {
  const response = await ahaApi.Account.hospitalFilter().then((res) => {
    return res;
  });

  return response;
});

export const hospitalFilterSlice = createSlice({
  name: "hospital",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getHospitalFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHospitalFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getHospitalFilter.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const hospitalFilterList = (state) => state.hospitalFilter.value;
export const hospitalFilterListLoading = (state) => state.hospitalFilter.loading;

export default hospitalFilterSlice.reducer;
