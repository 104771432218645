import React from "react";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { Button, Modal } from "react-bootstrap";
import { MAXIMUM_ORDER_SIZE_WARNING, ON_CALL_DELETE_SURGE_WARN, TIMEZONE_MISMATCH, TYPE_HOMEHEALTH, TYPE_ROUTINE, TYPE_STAT, TYPE_TIMEDDRAW } from "../../app/utils/Constants";

export default function StatusModal(props) {
  const { showCopyClipboard = false, copyToClipboard, additionalClass = '' } = props || {};
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.hideModal}
        className={`ad-adduser-modal sc-copycal-modal ${additionalClass || ""}`}
        backdrop="static"
        enforceFocus={false}
        data-testid="status-modal"
      >
        <Modal.Header
          data-testid="status-modal-close"
          closeButton
        ></Modal.Header>
        <Modal.Body className="text-center">
          {props.modalType.status === "confirmation" && (
            <div
              className="sch-confirmation-wrapper mb-2"
              data-testid="status-modal-title-icon"
            >
              <span className="confirmation-icon"></span>
            </div>
          )}
          <div className="sch-modal-body-bottom-content">
          <p
            data-testid="status-modal-confirmation-title"
            className={`${
              props.modalType.status === "confirmation"
                ? "confirmation-title  "
                : "success-title"
            }`}
          >
            {props.title}
          </p>
          <p
            data-testid="status-modal-subtitle"
            className="font-size-14 roboto-medium confirmation-subtitle mb-20 edit-inactive-user"
          >
            {props.subtitle || ""}
          </p>
          </div>
          {showCopyClipboard && <a className="btn popup-seenotes mb-3 d-flex justify-content-center align-items-center" onClick={copyToClipboard}><span class="copy-calendar pe-1"><span class="visually-hidden">Copy Icon</span></span>Copy Schedules</a>}
          {props.modalType.type === TYPE_STAT ||
          props.modalType.type === TYPE_ROUTINE ||
          props.modalType.type === TYPE_TIMEDDRAW ||
          props.modalType.type === TYPE_HOMEHEALTH ? (
            <div className="d-flex justify-content-center mb-2">
              <Button
                data-testid="status-modal-back"
                className="primary-btn primary-btn-outline outline"
                onClick={props.hideModal}
              >
                Back
              </Button>
              <ProgressButtonComponent
                data-testid="status-modal-save-button"
                content={"I'm  Sure"}
                spinSettings={{ position: "Center" }}
                animationSettings={{ effect: "SlideLeft" }}
                cssClass="primary-btn primary-btn-outline ad-progress-btn"
                onClick={props.confirmUpdate}
              ></ProgressButtonComponent>
            </div>
          ) : (
            props.modalType.status === "confirmation" && (
              <div className="d-flex justify-content-center mb-0 mt-3">
                <Button
                  data-testid="status-modal-back-schedule"
                  className="primary-btn primary-btn-outline outline"
                  onClick={props.hideModal}
                >
                  {props.modalType.type === "uncancelHomeHealth" || props.modalType.type === "overrideScheduleTimezone" || props.modalType.type === ON_CALL_DELETE_SURGE_WARN ? "No": props.modalType.type === "invalidTimezone" ? "Close" : props.modalType.type === MAXIMUM_ORDER_SIZE_WARNING ? "Ok" : "Cancel"}
                </Button>
                {props.modalType.type !== "invalidTimezone" && props.modalType.type !== MAXIMUM_ORDER_SIZE_WARNING && <ProgressButtonComponent
                  data-testid="status-modal-save-button-schedule"
                  content={
                    props.modalType.type === "updateFacility" || props.modalType.type === "updateHomeHealth"
                      ? "Save & Continue"
                      : props.modalType.type === "uncancelHomeHealth" || props.modalType.type === "overrideScheduleTimezone" || props.modalType.type === ON_CALL_DELETE_SURGE_WARN ? "Yes" : "Continue"
                  }
                  spinSettings={{ position: "Center" }}
                  animationSettings={{ effect: "SlideLeft" }}
                  cssClass="primary-btn primary-btn-outline ad-progress-btn"
                  onClick={() =>
                    props.modalType.type === "copySchedule"
                      ? props.copySchedule()
                      : props.modalType.type === "updateFacility" ||
                        props.modalType.type === "updateUser" || props.modalType.type === "updateHomeHealth" || props.modalType.type === "uncancelHomeHealth" || props.modalType.type === "overrideScheduleTimezone" ||
                        props.modalType.type === ON_CALL_DELETE_SURGE_WARN || props.modalType.type === TIMEZONE_MISMATCH
                      ? props.confirmUpdate()
                      : {}
                  }
                ></ProgressButtonComponent>}
              </div>
            )
          )}
        </Modal.Body>
      </Modal>
      {/* {lottie && <LottieAnimation />} */}
    </>
  );
}
