import * as React from "react";
import { Form, Button, FormGroup, Modal, Accordion, InputGroup } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { CustomDropdown } from "../../components/commons/CustomDropdown";
import { auditKeyDropOffVal, menuIsOpenValue, setAuditKeyDropOff, setMenuIsOpen } from "./Operations/checkinCheckoutSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { disableSaveForDropOff, setShowPopover, showPopoverVal } from "./Operations/scheduleListSlice";
import { convertToMMDDYYYY } from "../../app/utils/Constants";
import PlacesApiLoader from "../../components/commons/placesApiLoader";

const constructTimeOptionsTwo = () => {
	let options = [];
	for (let i = 0; i < 24; i++) {
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:00`,
			value: `${i < 10 ? "0" : ""}${i}:00`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:15`,
			value: `${i < 10 ? "0" : ""}${i}:15`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:30`,
			value: `${i < 10 ? "0" : ""}${i}:30`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:45`,
			value: `${i < 10 ? "0" : ""}${i}:45`,
		});
	}
	options = options.map((opt, index) => {
		return { ...opt, id: index };
	});
	return options;
};

const regexNumber = /^[0-9,.-]*$/;

export const DropOffOverlay = React.memo((props) => {
	const { overlayType, children, handleChangeDropOff, setNewDropOff, newDropOff, dropOffData = {}, addNewDroppOff, loadOptions, loadOptionsHos, handleChangeDropOffLatLng, dropOffId,
		tempCourierAddress, setTempCourierAddress, timezone_ids, showInvalidHosError, setShowInvalidHosError } = props;
	const { dropOffType = "lab", time = "", name = "", dropOffLng = "", dropOffLat = "", requisitions = [],
		selectedFiles = [], note = "", timezone = "" } = newDropOff;

	let isSaveDisabled = overlayType === "add" ? dropOffType === "courier" ? (!dropOffLat || !dropOffLng || !time || !note) : dropOffType === "hospital" ? (!time || !name || (!requisitions.length && !selectedFiles.length) || !note) : (!time || !name || !note) : overlayType === "COURIER" ? ((!dropOffLat && !dropOffLng && !time) || !note) : overlayType === "HOSPITAL" ? ((!time && !name && !requisitions.length && !selectedFiles.length) || !note) : ((!time && !name) || !note);

	const { time: dropoff_time, drop_location, latitude, longitude, audit_history = [], timezone: time_zone = {}, preferred_time, preferred_timezone = {} } = dropOffData;

	const [fileModal, setFileModal] = React.useState({ show: false });

	const dispatch = useDispatch();

	const menuIsOpen = useSelector(menuIsOpenValue);
	const auditKeyDropOff = useSelector(auditKeyDropOffVal);
	const showPopover = useSelector(showPopoverVal);
	const timeRef = React.useRef(null);
	const timezoneRef = React.useRef(null);
	const disableSaveButtonAfterApiCall = useSelector(disableSaveForDropOff)

	const [reRender, setReRender] = React.useState(false);

	let permission =
		(window.localStorage.getItem("userDetail") &&
			JSON.parse(window.localStorage.getItem("userDetail")).permission &&
			JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
		[];
	permission = permission.filter((perm) => perm.page === "events");
	const eventAccess = permission.length === 1 ? permission[0].access : [];
	const isExecutive = eventAccess && eventAccess.find((item) => item === "routine-bulk-action") === "routine-bulk-action" ? true : false;

	const handleFileUpload = (e) => {
		if (e.target.files) {
			let files = Array.prototype.slice.call(e.target.files);
			handleChangeDropOff('selectedFiles', files);
		}
	};

	const downloadFile = (fileName) => {
		var el = document.createElement("a");
		el.setAttribute("href", fileName);
		el.setAttribute("download", fileName);
		document.body.appendChild(el);
		el.click();
		el.remove();
	};

	const toggleDropOff = (e, dropOffId, timezone) => {
		e.stopPropagation();
		setNewDropOff({});
		setShowInvalidHosError(false);
		setTempCourierAddress({});
		let temp = {};
		if (timezone && timezone.timezone_id) {
			temp.timezone = {
				...timezone,
				value: timezone.timezone_id,
				label: timezone.timezone_name
			}
		}
		setNewDropOff(temp);
		dispatch(setShowPopover(`dropOff-${dropOffId}`));
	}

	const closeModal = () => {
		setShowInvalidHosError(false);
		setNewDropOff({});
		dispatch(setShowPopover(""));
		dispatch(setAuditKeyDropOff(""));
		dispatch(setMenuIsOpen({
			checkIn: false,
			checkOut: false,
			stopDay: false, time: false, spinTime: false,
			checkInTimezone: false, checkOutTimezone: false, spinTimeTimezone: false,
			dropOffTimezone: false
		}))
	}

	const toastModal = document.getElementsByClassName("toast-wrapper") || [];
	const statusModal = document.getElementsByClassName("ad-adduser-modal show") || [];
	let isModalOpen = false;
	if (toastModal && toastModal.length) {
		const displayText = window.getComputedStyle(toastModal[0]) && window.getComputedStyle(toastModal[0]).display;
		if (displayText === "block") {
			isModalOpen = true;
		} else if (displayText === "none") {
			if (statusModal && statusModal.length) {
				const displayText = window.getComputedStyle(statusModal[0]) && window.getComputedStyle(statusModal[0]).display;
				if (displayText === "block") {
					isModalOpen = true;
				} else if (displayText === "none") {
					isModalOpen = false
				}
			}
		}
	}
	return (
		<React.Fragment>
			<Popover
				key={dropOffId}
				id="dropoff-popover"
				containerClassName="sch-popover-container"
				isOpen={showPopover === `dropOff-${dropOffId}`}
				reposition={true}
				onClickOutside={(e) => {
					e.preventDefault();
					setReRender(!reRender);
					if (!isModalOpen) {
						closeModal();
					}
				}}
				content={
					({ position, childRect, popoverRect }) =>
						<ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
							position={position}
							childRect={childRect}
							popoverRect={popoverRect}
							arrowColor={'white'}
							arrowSize={10}
							arrowStyle={{ opacity: 1 }}
							className='popover-arrow-container'
							arrowClassName='popover-arrow'
						>
							<div
								title={(overlayType === "add" ? "Add" : overlayType) + ' Drop Off'}
								className="sch-popover sch-dropoff-popover"
								onClick={e => e.stopPropagation()}
							>
								<span className="service-icon2 popoverclose" onClick={() => document.body.click()}><span className="visually-hidden">Close Icon</span></span>
								<div className="scheduler-wrapper popover-wrapper">
									<div>
										<h3 className="title roboto-regular mb-3">{overlayType === "add" ? "Add" : overlayType} Drop off</h3>
									</div>
									<div className="schedule-accordian-wrapper accordion dropoff-schedule-wrapper">
										<div className="choose-time-wrapper flex-wrap dropoff-time-wrapper">
											<div className="schedule-time sch-timezone-wrapper">
												<div className="d-flex">
													<FormGroup className="mb-3">
														<Form.Label htmlFor="dropoff-time">Time</Form.Label>
														<CustomDropdown
															name=""
															id="dropoff-time"
															className="sch-schedule-dropdown sch-schedule-select__control"
															menuIsOpen={menuIsOpen.time || false}
															setMenuIsOpen={(val) => dispatch(setMenuIsOpen({ time: val }))}
															wrapperRef={timeRef}
															options={constructTimeOptionsTwo()}
															onChange={e => handleChangeDropOff("time", e, overlayType === "add" ? dropOffType : overlayType.toLowerCase(), overlayType, dropOffData)}
															value={time}
															placeholder="Choose"
															isDisabled={!isExecutive}
														/>
														<span className="d-block roboto-bold form-timezone-text">{overlayType !== "add" && dropoff_time && convertToMMDDYYYY(dropoff_time, time_zone, "Actual", true, true)}</span>
														<span className="d-block roboto-bold form-timezone-text">{preferred_time && convertToMMDDYYYY(preferred_time, preferred_timezone, "Preferred", true, true)}</span>
													</FormGroup>
													<FormGroup className="mb-3 check-in-timezone-select">
														<Form.Label htmlFor="dropoff-timezone">Timezone</Form.Label>
														<CustomDropdown
															name=""
															id="dropoff-timezone"
															className="sch-schedule-dropdown sch-schedule-select__control"
															menuIsOpen={menuIsOpen.dropOffTimezone || false}
															setMenuIsOpen={(val) => dispatch(setMenuIsOpen({ dropOffTimezone: val }))}
															wrapperRef={timezoneRef}
															options={timezone_ids}
															onChange={e => handleChangeDropOff("timezone", e, overlayType === "add" ? dropOffType : overlayType.toLowerCase(), overlayType, dropOffData)}
															value={timezone}
															placeholder="Tz"
															isDisabled={!isExecutive}
															showLabel={true}
														/>
													</FormGroup>
												</div>
												{overlayType === "add" && <FormGroup className="mb-3">
													<Form.Label className="d-block" htmlFor="">Choose Drop Off Type</Form.Label>
													<div className="d-flex">
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																id="lab-radio"
																name="preffered"
																value={"lab"}
																defaultChecked={
																	dropOffType === "lab" ? "true" : "false"
																}
																checked={dropOffType === "lab" ? true : false}
																onChange={(e) => { handleChangeDropOff("dropOffType", e.target.value) }}
																disabled={!isExecutive}
															/>
															<label className="form-check-label font-size-14" for="lab-radio">
																Lab
															</label>
														</div>
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																id="hospital-radio"
																name="preffered"
																value={"hospital"}
																defaultChecked={
																	dropOffType === "hospital" ? "true" : "false"
																}
																checked={dropOffType === "hospital" ? true : false}
																onChange={(e) => { handleChangeDropOff("dropOffType", e.target.value); }}
																disabled={!isExecutive}
															/>
															<label className="form-check-label font-size-14" for="hospital-radio">
																Hospital
															</label>
														</div>
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																id="courier-radio"
																name="preffered"
																value={"courier"}
																defaultChecked={
																	dropOffType === "courier" ? "true" : "false"
																}
																checked={dropOffType === "courier" ? true : false}
																onChange={(e) => handleChangeDropOff("dropOffType", e.target.value)}
																disabled={!isExecutive}
															/>
															<label className="form-check-label font-size-14" for="courier-radio">
																Courier
															</label>
														</div>
													</div>
												</FormGroup>}
												{(overlayType === "add" ? dropOffType !== "courier" : overlayType !== "COURIER") && <FormGroup className={`mb-3 drop-off-select ${showInvalidHosError ? "error-message" : ""}`}>
													<Form.Label htmlFor="dropoff-name">Name<span className="d-block roboto-bold form-timezone-text">{overlayType !== "add" && drop_location && ` ${drop_location}`}</span></Form.Label>
													<AsyncSelect
														// defaultOptions
														loadOptions={text => (overlayType === "add" ? dropOffType : overlayType.toLowerCase()) === "hospital" ? loadOptionsHos(text, time &&time.value ? time : dropoff_time ? {value: dropoff_time} : "", timezone) : loadOptions(text)}
														isSearchable
														onChange={(e) => handleChangeDropOff("name", e, overlayType === "add" ? dropOffType : overlayType.toLowerCase(), overlayType, dropOffData)}
														// hideSelectedOptions
														isLoading={false}
														isClearable
														name=""
														id="dropoff-name"
														className="sch-schedule-dropdown"
														classNamePrefix="sch-schedule-select"
														placeholder="Choose"
														value={name}
														isDisabled={!isExecutive}
													/>
													{showInvalidHosError && (
														<p data-testid="error-text-id" className="error-message-text">
															Select a valid hospital
														</p>
													)}
												</FormGroup>}

												{(overlayType === "add" ? dropOffType === "hospital" : overlayType === "HOSPITAL") &&
													<div className="do-attachment-wrapper aha-modalform-group mb-3">
														<label className="caption" htmlFor="choose-file">
															Attachment
														</label>

														<input
															accept="image/*"
															className="attachment-file"
															id="choose-file"
															type="file"
															onChange={handleFileUpload}
															onClick={(event) => {
																event.target.value = null;
															}}
															multiple
															disabled={!isExecutive}
															onError={e => {
															}}
														/>
														<p className="do-selected-file">{selectedFiles &&
															selectedFiles.length > 0 ? selectedFiles.length === 1 ?
															selectedFiles[0].name : `${selectedFiles.length} files` : ""}</p>
													</div>}
												{(overlayType === "add" ? dropOffType === "hospital" : overlayType === "HOSPITAL") && <div className="view-attachment">
													{requisitions.map((file, idx) => {
														return (
															<p key={file.id}>
																<span className="file-name">{file.file_name}</span>
																<span
																	className="view-btn"
																	onClick={() =>
																		setFileModal({ ...file, show: true })
																	}
																>
																	View Attachment
																</span>
															</p>
														);
													})}
												</div>}
												<div className="d-flex dropoff-lat-long">
													{/* for add and edit courier drop-off */}
													{(overlayType === "COURIER" || dropOffType === "courier") && <FormGroup className="mb-3 w-245">
														<Form.Label htmlFor="lat">
															Select Location<span className="d-block roboto-bold form-timezone-text">
																{overlayType !== "add" && latitude && longitude && `Lat: ${latitude}, Lng: ${longitude}`}
															</span></Form.Label>
														<PlacesApiLoader pageType = "dropOff" tempCourierAddress={tempCourierAddress} setTempCourierAddress={setTempCourierAddress} id={dropOffId}
															handleChangeLatLng={handleChangeDropOffLatLng} newDropOff={newDropOff} latitude={dropOffLat}
															longitude={dropOffLng} disabled={!isExecutive} />
														{/* {dropOffLat && dropOffLng &&  `Lat: ${dropOffLat}, Lng: ${dropOffLng}`} */}
													</FormGroup>}

													{/* for view lab and hospital drop-off */}
													{(overlayType === "LAB" || overlayType === "HOSPITAL") && <FormGroup className="mb-3 me-2">
														<Form.Label htmlFor="lat2">Latitude<span className="d-block roboto-bold form-timezone-text"></span></Form.Label>
														<input
															type="text"
															className="form-control"
															id="lat2"
															onChange={e => (regexNumber.test(e.target.value) || e.target.value === "") && handleChangeDropOff("dropOffLat", e.target.value)}
															value={latitude}
															disabled
														/>
													</FormGroup>}
													{(overlayType === "LAB" || overlayType === "HOSPITAL") && <FormGroup className="mb-3 ms-2">
														<Form.Label htmlFor="long2">Longitude<span className="d-block roboto-bold form-timezone-text"></span></Form.Label>
														<input
															type="text"
															className="form-control"
															id="long2"
															onChange={e => (regexNumber.test(e.target.value) || e.target.value === "") && handleChangeDropOff("dropOffLng", e.target.value)}
															value={longitude}
															disabled
														/>
													</FormGroup>}
												</div>
												<div className="schedule-time sch-notes-wrapper p-0">
													<InputGroup className="mb-1">
														<Form.Label htmlFor="check-out-time">
															Notes
														</Form.Label>
														<div className="position-relative w-100 aha-modalform-group">
															<textarea
																value={note}
																onChange={(e) => handleChangeDropOff("note", e.target.value)}
																disabled={!isExecutive}
															/>
														</div>
													</InputGroup>
													<Accordion activeKey={auditKeyDropOff} onSelect={(val) => {
														dispatch(setAuditKeyDropOff(val))
													}}>
														<Accordion.Item eventKey="0">
															<Accordion.Header className="audit-log-header">Audit Log
																<span className="al-arrow-down"></span> </Accordion.Header>
															<Accordion.Body>
																{/* For checkin */}
																<ul className="audit-list-wrapper justify-content-between flex-wrap">
																	{audit_history.length > 0 ? audit_history.map((act, idx) => {
																		const { user = {}, activity, datetime = "", notes = "", type, abbreviation } = act;
																		return (
																			<li key={idx}>
																				<span className="al-activity roboto-bold">{moment(datetime).format("MM/DD/YYYY HH:mm:ss")} {abbreviation || ""}</span>
																				<span className="d-flex"> <span className="al-activity pe-1">{activity} {user ? ` By: @${user.username}` : ""}<i>{type ? ` (${type})` : ""}</i></span>
																					<span className="al-activity"></span> </span>
																				{notes && <span className="al-activity">Notes: {notes}</span>}
																			</li>
																		)
																	})
																		: <p className="font-size-12 grey-color">No logs to show</p>
																	}
																</ul>
															</Accordion.Body>
														</Accordion.Item>
													</Accordion>
												</div>
											</div>
										</div>
									</div>
									<div className="d-flex checkin-btn-wrapper justify-content-end">
										<Button className="primary-btn primary-btn-outline outline" onClick={() => { setShowInvalidHosError(false); setNewDropOff({}); setTempCourierAddress({}) }}>clear</Button>
										<Button className="primary-btn primary-btn-outline"
											disabled={isSaveDisabled || !timezone || !timezone.value || disableSaveButtonAfterApiCall || showInvalidHosError} onClick={() => addNewDroppOff(overlayType !== "add" ? dropOffData.id : 0, overlayType)}>Save</Button>
									</div>
								</div>
							</div>
						</ArrowContainer>
				}
			>
				<div onClick={(e) => toggleDropOff(e, dropOffId, time_zone)}>
					{children}
				</div>
			</Popover>
		</React.Fragment>
	);
});
