import * as React from "react";
import { Facility } from "./Facility";
import { BACKLOG_SECTION_TYPE } from "../../app/utils/Constants";

export const Routine = React.memo((props) => {
  const { events, userIndex, serviceIndex, zoneIndex, sectionType, date, constructPyloadForCheckinCheckout, constructPayloadToClearCheckinCheckoutSpin, user_id, getScheduleBoardData,
    blockId, uniqueServiceId, hasRouteStarted, showDropArea, timezone_ids, showInactiveFac, viewAttachment } =
    props;
  return (
    <React.Fragment>
      {Object.keys(events).map((event_type_id) => {
        const { event_type: eventType, facilities = [], event_id } =
          events[event_type_id];

        const lastFacilityObject = facilities ? facilities.length ? facilities[facilities.length - 1] : {} : {};
        const lastFacilityId = lastFacilityObject.facility_id || "";
        return (
          <React.Fragment
            key={
              userIndex +
              "-" +
              serviceIndex +
              "-" +
              zoneIndex +
              "-" +
              event_type_id
            }
          >
            {events &&
              events[event_type_id] &&
              events[event_type_id].facilities &&
              events[event_type_id].facilities.length > 0 && (
                <div className="sub-serivce-type">
                  <span className={`${event_type_id === "surge" ? "icon-add-user" : event_type_id === "nonserviceday" ? "icon-non_service" : "service-icon"}`}>
                    <span className="visually-hidden">
                      Facilities: {eventType}
                    </span>
                  </span>
                  <p className="title">Facilities: {eventType}</p>
                </div>
              )}
            <div
              className={sectionType === BACKLOG_SECTION_TYPE ? "" : `${showDropArea ? "droppable-area-border " : " "}`}
            //  style={sectionType === BACKLOG_SECTION_TYPE ? {} : {opacity: showDropArea ? 0.8 : 1, border: showDropArea ? '5px dotted green' : "none"}}
            >
              <Facility
                facilities={facilities}
                date={date}
                userIndex={userIndex}
                serviceIndex={serviceIndex}
                zoneIndex={zoneIndex}
                routineIndex={event_type_id}
                sectionType={sectionType}
                eventType={eventType}
                eventId={events[event_type_id].event_id}
                handleDelete={props.handleDelete}
                constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
                constructPayloadToClearCheckinCheckoutSpin={constructPayloadToClearCheckinCheckoutSpin}
                event_id={event_id}
                user_id={user_id}
                getScheduleBoardData={getScheduleBoardData}
                uniqueServiceId={uniqueServiceId}
                blockId={blockId}
                hasRouteStarted={hasRouteStarted}
                showDropArea={showDropArea}
                lastFacilityId={lastFacilityId}
                timezone_ids={timezone_ids}
                showInactiveFac={showInactiveFac}
                viewAttachment={viewAttachment}
              />
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
});
