import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
export const initialState = {
  value: [],
  loading: false
};

export const addAlloMapDrawSite = createAsyncThunk(
  "alloMap/addAlloMap",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.addDrawSite(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const addAlloMapSlice = createSlice({
  name: "addAlloMap",
  initialState,
  reducers: {
    clearAddAlloMapResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAlloMapDrawSite.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAlloMapDrawSite.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        state.days = action.payload.days;
      })
      .addCase(addAlloMapDrawSite.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const addAlloMapResponse = (state) => state.addAlloMap.value;
export const {clearAddAlloMapResponse} = addAlloMapSlice.actions;
export default addAlloMapSlice.reducer;
