import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const fetchAlloMapDrawSite = createAsyncThunk(
  "alloMap/fetch",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.fetchDrawSite(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const fetchAlloMapSlice = createSlice({
  name: "alloMap",
  initialState,
  reducers: {
    setFetchedAlloMapResponse(state, action) {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlloMapDrawSite.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlloMapDrawSite.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(fetchAlloMapDrawSite.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const fetchAlloMapResponse = (state) => state.fetchAlloMap.value;
export const fetchAlloMapLoader = (state) => state.fetchAlloMap.loading;
export const { setFetchedAlloMapResponse } = fetchAlloMapSlice.actions;

export default fetchAlloMapSlice.reducer;
