import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../app/services/aha-api";
import { ORDER_TYPE_HOME_HEALTH, ORDER_TYPE_ROUTINE_REDRAW, ORDER_TYPE_STAT, ORDER_TYPE_TIMED_DRAW, TYPE_HOMEHEALTH } from "../../app/utils/Constants";
const initialState = {
  value: [],
  loading: false,
  isExportDisabledStat: localStorage.getItem(`export-${ORDER_TYPE_STAT}`) === "true" ? true : false,
  isExportDisabledRoutine: localStorage.getItem(`export-${ORDER_TYPE_ROUTINE_REDRAW}`) === "true" ? true : false,
  isExportDisabledTimed: localStorage.getItem(`export-${ORDER_TYPE_TIMED_DRAW}`) === "true" ? true : false,
  isExportDisabledHomeHealth: localStorage.getItem(`export-${ORDER_TYPE_HOME_HEALTH}`) === "true" ? true : false,
};

export const doOrderExport = createAsyncThunk(
  "order/export",
  async (data, { rejectWithValue }) => {
    const endpoint = data.type === ORDER_TYPE_HOME_HEALTH ? 'doHomeHealthExport' : 'doStatExport';
    const response = await ahaApi.Account[endpoint](data.params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const exportSlice = createSlice({
  name: "export",
  initialState,
  reducers: {
    setExportData(state, action) {
      state.value = action.payload;
    },
    setIsExportDisabledStat(state, action) {
      state.isExportDisabledStat = action.payload;
    },
    setIsExportDisabledRoutine(state, action) {
      state.isExportDisabledRoutine = action.payload;
    },
    setIsExportDisabledTimed(state, action) {
      state.isExportDisabledTimed = action.payload;
    },
    setIsExportDisabledHomeHealth(state, action) {
      state.isExportDisabledHomeHealth = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(doOrderExport.pending, (state) => {
        state.loading = true;
      })
      .addCase(doOrderExport.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(doOrderExport.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const exportDataResponse = (state) => state.orderExport.value;
export const exportDataLoader = (state) => state.orderExport.loading;

export const isExportDisabledStat = (state) => state.orderExport.isExportDisabledStat;
export const isExportDisabledRoutine = (state) => state.orderExport.isExportDisabledRoutine;
export const isExportDisabledTimed = (state) => state.orderExport.isExportDisabledTimed;
export const isExportDisabledHomeHealth = (state) => state.orderExport.isExportDisabledHomeHealth;
export const { setExportData, setIsExportDisabledStat, setIsExportDisabledRoutine, setIsExportDisabledTimed, setIsExportDisabledHomeHealth } = exportSlice.actions;

export default exportSlice.reducer;

